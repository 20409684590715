import { getBasename, joinUrlParts } from '../../libs/urlUtils';
import { stringify } from '../../libs/queryString';

export class ApiRoutes {
  private readonly basename: string;

  constructor(baseURL: string) {
    this.basename = getBasename(baseURL);
  }

  public templates = (props: { criteria?: string; category?: string; subCategory?: string; page?: number }): string => {
    const path = props.criteria ? `api/search` : `api/templates`;

    const query = stringify({
      ...(props.category && { category: props.category }),
      ...(props.subCategory && { 'sub-category': props.subCategory }),
      ...(props.criteria && { criteria: props.criteria }),
      page: String(props.page || 1),
    });

    return joinUrlParts('/', this.basename, `${path}?${query}`);
  };

  preview = (metaSiteId: string) => joinUrlParts('/', this.basename, `api/preview/${metaSiteId}/mobile`);
}
