import React from 'react';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { OverriddenCategoryNamesStore } from '../../../stores/OverriddenCategoryNamesStore';
import { injectStoresV1 } from '../../stores/injectStoresV1';

import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import s from './MobileTemplatesHeading.scss';

export interface MobileTemplatesHeadingProps extends WithTranslations {
  categoriesStore: CategoriesStore;
  templatesStore: TemplatesStore;
  overriddenCategoryNamesStore: OverriddenCategoryNamesStore;
  dataHook?: string;
}

function getMobileHeadingText(props: MobileTemplatesHeadingProps): string | null {
  const { categoriesStore, templatesStore, overriddenCategoryNamesStore, t } = props;
  const {
    loaded: { criteria: searchCriteria },
  } = templatesStore;
  const { activeCategory, activeSubCategory, activeMicroCategory } = categoriesStore;

  if (searchCriteria) {
    return t('subHeader.subTitle.search', { query: searchCriteria });
  }

  if (activeCategory?.id === 'all') {
    return t('subHeader.subTitle.allTemplates');
  }

  const category = activeMicroCategory || activeSubCategory || activeCategory;
  if (category) {
    const categoryName = overriddenCategoryNamesStore.getPageTitleCategoryName(category);
    return t('subHeader.subTitle.category.v2', { category: categoryName });
  }

  return null;
}

function getHeadingTagName({ categoriesStore }: MobileTemplatesHeadingProps): 'h1' | 'h2' {
  const { activeCategory } = categoriesStore;
  return activeCategory?.id === 'all' ? 'h2' : 'h1';
}

const MobileTemplatesHeadingCmp: React.FC<MobileTemplatesHeadingProps> = (props) => {
  const text = getMobileHeadingText(props);
  const TagName = getHeadingTagName(props);

  if (!text) {
    return null;
  }

  return (
    <TagName className={s.heading} data-hook={props.dataHook}>
      {text}
    </TagName>
  );
};

export const MobileTemplatesHeading = withTranslations(
  injectStoresV1('categoriesStore', 'templatesStore', 'overriddenCategoryNamesStore')(MobileTemplatesHeadingCmp),
);
