import React from 'react';
import { WithTranslation } from '@wix/wix-i18n-config';
import { CbdBanner } from '../../../v2/components/CbdBanner/CbdBanner';
import { ConfigStore } from '../../stores/ConfigStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { MetaStore } from '../../stores/MetaStore';
import { RoutingStore } from '../../stores/RoutingStore';
import { TemplatePreviewStore } from '../../stores/TemplatePreviewStore';
import { InteractionsStore } from '../../stores/InteractionsStore';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { getLogger, stopActiveInteraction } from '../../utils/fedops-logger';
import { biLogger } from '../../utils/BILogger';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { mapTemplatesToJson } from '../../../utils/templatesToBiData';
import { withTranslations } from '../../../utils/withTranslations';
import { Template } from './Template/Template';
import { TemplateNew } from './TemplateNew/TemplateNew';
import s from './Templates.scss';

export interface TemplatesProps extends WithTranslation {
  dataHook?: string;
  templatesStore: TemplatesStore;
  categoriesStore: CategoriesStore;
  isCbdBannerEnabled?: boolean;
  routingStore: RoutingStore;
  templatePreviewStore: TemplatePreviewStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
  metaStore: MetaStore;
  interactionsStore: InteractionsStore;
}

const TemplatesCmp: React.FC<TemplatesProps> = ({
  isCbdBannerEnabled,
  configStore,
  templatesStore,
  routingStore,
  categoriesStore,
  metaStore,
  experimentsStore,
  t,
}) => {
  const logTemplatesLoadedBiEvent = () => {
    const isSearch = routingStore.isSearchResultsPage;
    const { activeCategory, activeSubCategory } = categoriesStore;
    let screenType = 'category';

    if (routingStore.appPage === 'home') {
      screenType = 'main';
    } else if (isSearch) {
      screenType = 'search_results';
    }

    biLogger.logTemplatesLoaded({
      eventData: mapTemplatesToJson(templatesStore.templates).toString(),
      category: activeCategory?.id ?? 'search',
      sub_category: activeSubCategory?.id ?? '',
      is_search_result: isSearch,
      total_search_results: isSearch ? templatesStore.itemsTotal : 0,
      search_guid: templatesStore.searchId,
      screen_type: screenType,
      criteria: routingStore.searchQuery,
      page_number: routingStore.page || 1,
      origin: configStore.config.introReferrer || 'organic',
    });
  };

  React.useEffect(() => {
    stopActiveInteraction();
    templatesStore.reportPageRendered();
    logTemplatesLoadedBiEvent();
  }, [templatesStore.templateKeyPrefix]);

  const { templates } = templatesStore;
  const isViewerForMobileEnabled = experimentsStore.is('specs.funnel.templateViewerV2', 'true');
  const category = categoriesStore.activeSubCategory || categoriesStore.activeCategory;
  const originUrl = routingStore.routes.toAbsolute(
    routingStore.canonicalLink || routingStore.history.createHref(routingStore.location),
  );

  return (
    <div>
      {isCbdBannerEnabled && (
        <div className={s.cbdBannerWrapper}>
          <CbdBanner geoData={configStore.config.geoData} />
        </div>
      )}

      {templates && (
        <ul aria-label={t('templates.label')} data-hook="templates" className={s.templates}>
          {templates.map((template, index) => {
            if (isViewerForMobileEnabled) {
              return (
                <TemplateNew
                  originUrl={originUrl}
                  language={metaStore.language}
                  index={index}
                  key={`${index}-${templatesStore.templateKeyPrefix}-${template.id}`}
                  template={template}
                  category={category}
                  query={routingStore.searchQuery}
                  dataHook="template"
                />
              );
            }

            return (
              <Template
                originUrl={originUrl}
                language={metaStore.language}
                index={index}
                key={`${index}-${templatesStore.templateKeyPrefix}-${template.id}`}
                template={template}
                category={category}
                query={routingStore.searchQuery}
                fedopsLogger={getLogger()}
                dataHook="template"
              />
            );
          })}
        </ul>
      )}
    </div>
  );
};

export const Templates = withTranslations(
  injectStoresV1(
    'templatesStore',
    'categoriesStore',
    'routingStore',
    'templatePreviewStore',
    'configStore',
    'experimentsStore',
    'metaStore',
    'interactionsStore',
  )(TemplatesCmp),
);
