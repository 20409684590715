import React from 'react';
import debounce from 'lodash/debounce';
import { WithTranslation } from '@wix/wix-i18n-config';
import * as bi from '../../../utils/BILogger';
import { TemplateViewBIOrigin } from '../../../utils/BILogger';
import { CategoryModel } from '../../../model/CategoryModel';
import { TemplateModel } from '../../../model/TemplateModel';
import { isElementInView } from '../../../../utils/isElementInView';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { RoutingStore } from '../../../stores/RoutingStore';
import { injectStoresV1 } from '../../../stores/injectStoresV1';
import { withTranslations } from '../../../../utils/withTranslations';
import { CategoriesStore } from '../../../stores/CategoriesStore';
import { getViewerLink } from '../../../../utils/templateLinks';
import { EditorSessionIdContext } from '../../../../v2/contexts/EditorSessionIdContext';
import { TemplateThumbnail } from '../../TemplateThumbnail/TemplateThumbnail';
import s from './TemplateNew.scss';

interface Cancelable {
  cancel(): void;
  flush(): void;
}

export interface TemplateNewProps extends WithTranslation {
  dataHook?: string;
  language: string;
  onView?: Function;
  template: TemplateModel;
  category: CategoryModel;
  query: string;
  index: number;
  originUrl: string;
  templatesStore: TemplatesStore;
  routingStore: RoutingStore;
  impressionHandlerDelay?: number;
  categoriesStore: CategoriesStore;
}

interface DebouncedFunc extends Cancelable {
  (): void;
}

class TemplateNewCmp extends React.Component<TemplateNewProps> {
  static contextType = EditorSessionIdContext;
  private viewButtonRef: HTMLAnchorElement;
  public debouncedReportIfVisibleToUser: DebouncedFunc;

  constructor(props: TemplateNewProps) {
    super(props);

    const { impressionHandlerDelay = 500 } = this.props;
    this.debouncedReportIfVisibleToUser = debounce(this.reportIfVisibleToUser, impressionHandlerDelay);
  }

  public setViewButtonRef = (ref: HTMLAnchorElement) => (this.viewButtonRef = ref);

  public reportIfVisibleToUser = async () => {
    const { templatesStore, template } = this.props;

    if (templatesStore.wasTemplateInView(template.id)) {
      return;
    }

    if (isElementInView(this.viewButtonRef)) {
      templatesStore.addViewedTemplateId(template.id);
      await this.bi.impression();
    }
  };

  public componentDidMount() {
    void this.reportIfVisibleToUser();
    window.addEventListener('scroll', this.debouncedReportIfVisibleToUser);
    window.addEventListener('resize', this.debouncedReportIfVisibleToUser);
  }

  public componentWillUnmount() {
    this.debouncedReportIfVisibleToUser.cancel();
    window.removeEventListener('scroll', this.debouncedReportIfVisibleToUser);
    window.removeEventListener('resize', this.debouncedReportIfVisibleToUser);
  }

  private bi = {
    view: (biProps: { origin: TemplateViewBIOrigin }) => {
      const viewLink = this.getViewLink();
      const { activeCategory, activeSubCategory } = this.props.categoriesStore;
      const isSearch = this.props.routingStore.isSearchResultsPage;

      return bi.biLogger.logTemplateView({
        index: this.props.index,
        category: activeCategory?.id ?? '',
        subCategory: activeSubCategory?.id ?? '',
        openingSiteId: this.props.template.siteId,
        isSearchResult: isSearch,
        currentPageNumber: this.props.routingStore.page || 1,
        btn: true,
        openingBrowserUrl: viewLink,
        score: this.props.template.score,
        siteType: 2,
        total_search_results: 0,
        search_guid: this.props.templatesStore.searchId,
        origin: biProps.origin,
      });
    },
    impression: () => {
      return bi.biLogger.logTemplateInViewport({
        category: this.props.templatesStore.biCategory,
        siteType: 2,
        template_id: this.props.template.siteId,
        page_index: this.props.templatesStore.page + 1,
        galleryDocIndex: this.props.index,
      });
    },
  };

  private handleView = (event: React.MouseEvent) => {
    this.bi
      .view({ origin: (event.target as HTMLElement).getAttribute('data-bi-origin') as TemplateViewBIOrigin })
      .then(() => {
        this.props.onView && this.props.onView();
        this.context.refreshEditorSessionId();
      });
  };

  private getViewLink() {
    const templateSlug = this.props.template.id;
    const language = this.props.language;
    const originUrl = this.props.originUrl;
    const editorSessionId = this.context.editorSessionId;

    return getViewerLink({ templateSlug, language, originUrl, editorSessionId });
  }

  public render() {
    const { template, index, dataHook, t, query } = this.props;
    const category = query || this.props.category?.title;
    const imgAlt = t('template.previewAlt', { category, template: template.title });

    return (
      <li key={template.id} className={s.template} data-hook={dataHook} data-bi-gallery-doc-index={index}>
        <a
          className={s.mobileThumbLabel}
          onClick={this.handleView}
          href={this.getViewLink()}
          target="_blank"
          data-bi-origin="mobile_thumbnail"
          data-bi-element="template_thumbnail_mobile"
          data-bi-element-value={template.siteId}
          data-hook="viewTemplateLink"
        >
          <TemplateThumbnail
            className={s.mobileThumb}
            dataHook="mobileThumb"
            ariaLabel={t('template.mobilePreviewImg', { template: template.title })}
            alt={imgAlt}
            lazyLoad={index > 1}
            isMobileImage={true}
            imagePath={template.mobileImg}
          />
        </a>
        <span
          className={s.title}
          data-hook="template-title"
          data-bi-origin="title"
          data-bi-element="template_title"
          data-bi-element-value={template.siteId}
        >
          {template.title}
        </span>
      </li>
    );
  }
}

export const TemplateNew = withTranslations(
  injectStoresV1('routingStore', 'templatesStore', 'categoriesStore')(TemplateNewCmp),
);
