import React from 'react';
import { withTranslations, WithTranslations } from '../../../../utils/withTranslations';
import Info from '../../../../Icons/Info.svg';
import ReadMoreArrowRight from '../../../../Icons/ReadMoreArrowRight.svg';
import { injectStoresV1 } from '../../../stores/injectStoresV1';
import { RoutingStore } from '../../../stores/RoutingStore';
import s from './ReadMoreButton.scss';

interface ReadMoreButtonProps extends WithTranslations {
  dataHook?: string;
  routingStore: RoutingStore;
}

const ReadMoreButtonCmp: React.FC<ReadMoreButtonProps> = ({ routingStore, t, dataHook }) => {
  const handleClick = () => {
    routingStore.goToScreen('details', routingStore.templateSlug);
  };

  return (
    <button onClick={handleClick} className={s.button} data-hook={dataHook}>
      <Info className={s.info} />
      <span>{t('template.newMobileFlow.preview.readMore')}</span>
      <ReadMoreArrowRight className={s.arrow} />
    </button>
  );
};

export const ReadMoreButton = withTranslations(injectStoresV1('routingStore')(ReadMoreButtonCmp));
