import { BreadcrumbList, ListItem } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { RoutingStore } from '../../stores/RoutingStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { TFunction } from '../../../i18n/i18n';
import { ConfigStore } from '../../stores/ConfigStore';
import { getBreadcrumbs } from '../../../utils/getBreadcrumbs';
import { CategoryModel } from '../../model/CategoryModel';
import { Category } from '../../../web-api/domain/category';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';

interface GenerateActiveCategoryListItemParams {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
}

function generateActiveCategoryListItemPropsV1({
  routingStore,
  categoriesStore,
}: GenerateActiveCategoryListItemParams): Pick<ListItem, 'item' | 'name'> | null {
  const { appPage } = routingStore;
  const activeCategory = appPage !== 'home' && categoriesStore.activeCategory;

  if (!activeCategory) {
    return null;
  }

  const { routes } = routingStore;

  return {
    item: routes.toAbsolute(routes.categoryRoute.get(activeCategory.id)),
    name: activeCategory.title,
  };
}

interface GenerateActiveSubCategoryListItemParams {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
}

function generateActiveSubCategoryListItemPropsV1({
  routingStore,
  categoriesStore,
}: GenerateActiveSubCategoryListItemParams): Pick<ListItem, 'item' | 'name'> | null {
  const { activeSubCategory, activeCategory } = categoriesStore;

  if (!activeCategory || !activeSubCategory) {
    return null;
  }

  const { routes } = routingStore;

  return {
    item: routes.toAbsolute(routes.subCategoryRoute.get(activeCategory.id, activeSubCategory.id)),
    name: activeSubCategory.title,
  };
}

const generateAllBreadcrumbsToMicroCategoryV1 = ({
  routingStore,
  categoriesStore,
}: GenerateActiveSubCategoryListItemParams): Pick<ListItem, 'item' | 'name'>[] => {
  const { microCategorySlug } = routingStore;
  const { activeMicroCategory, categories } = categoriesStore;

  if (!activeMicroCategory || !microCategorySlug) {
    return [];
  }

  const DTOCategories = categories.map(CategoryModel.toDTO) as Category[];

  const microCategories = categoriesStore.intentCategories.filter(({ items }) =>
    items.find(({ id }) => id === microCategorySlug),
  );

  const name = microCategories.flatMap(({ items }) => items).find(({ id }) => id === microCategorySlug)?.title;

  const breadcrumbs = microCategories
    .map(({ id }) =>
      getBreadcrumbs({
        destinationCategorySlug: id,
        categories: DTOCategories,
      }),
    )
    .reduce((acc, curr) => (acc.length > curr.length ? acc : curr), []);

  if (!breadcrumbs?.length) {
    return [];
  }

  const { routes } = routingStore;
  const [category, subCategory] = breadcrumbs ?? [];

  return [
    {
      item: routes.toAbsolute(routes.categoryRoute.get(category.name)),
      name: category.displayName,
    },
    ...(subCategory
      ? [
          {
            item: routes.toAbsolute(routes.subCategoryRoute.get(category.name, subCategory.name)),
            name: subCategory.displayName,
          },
        ]
      : []),
    {
      item: routes.toAbsolute(routes.microCategoryRoute.get(microCategorySlug)),
      name,
    },
  ];
};

function buildHomePageURL(configStore: ConfigStore) {
  const { domain, currentLanguage } = configStore.config;
  const langDomain = `${currentLanguage === 'en' ? 'www' : currentLanguage}.${domain}`;
  return `https://${langDomain}`;
}

interface GenerateBreadcrumbListScriptPropsParamsV1 {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
  t: TFunction;
}

type BreadcrumbListScriptProps = ReturnType<typeof helmetJsonLdProp>;

export function generateBreadcrumbListScriptPropsV1({
  t,
  routingStore,
  categoriesStore,
  configStore,
  experimentsStore,
}: GenerateBreadcrumbListScriptPropsParamsV1): BreadcrumbListScriptProps {
  const { routes, microCategorySlug } = routingStore;
  const isMicroCategoryPage = Boolean(microCategorySlug);
  const isEnabledMicroCategories =
    experimentsStore.is('specs.funnel.MarketingTemplatesMicroCategories', 'true') &&
    experimentsStore.is('specs.funnel.TemplatesBulkandMicrocatsEN', 'true');

  return helmetJsonLdProp<BreadcrumbList>({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        name: t('breadcrumbs.wixcom'),
        item: buildHomePageURL(configStore),
      },
      {
        name: t('breadcrumbs.templates'),
        item: routes.toAbsolute(routes.base.get()),
      },
      ...(isMicroCategoryPage && isEnabledMicroCategories
        ? generateAllBreadcrumbsToMicroCategoryV1({ routingStore, categoriesStore })
        : [
            generateActiveCategoryListItemPropsV1({ routingStore, categoriesStore }),
            generateActiveSubCategoryListItemPropsV1({ routingStore, categoriesStore }),
          ]),
    ]
      .filter((item) => item !== null)
      .map((params, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        ...params,
      })),
  });
}
