import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import PaginationLast from '../../../Icons/PaginationLast.svg';
import PaginationNext from '../../../Icons/PaginationNext.svg';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { InteractionsStore } from '../../stores/InteractionsStore';
import { SeoLink } from '../SeoLink/SeoLink';
import * as bi from '../../utils/BILogger';
import { RoutingStore } from '../../stores/RoutingStore';
import { startPaginationInteraction } from '../../utils/fedops-logger';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import s from './Pagination.scss';

export interface PaginationProps extends WithTranslations {
  dataHook?: string;
  templatesStore: TemplatesStore;
  experimentsStore: ExperimentsStore;
  interactionsStore: InteractionsStore;
  routingStore: RoutingStore;
}

interface LIProps {
  dataHook?: string;
  text?: React.ReactNode;
  key: string;
  className?: string;
  disabled?: boolean;
  current?: boolean;
  notLink?: boolean;
  to: string;
  label?: string;
  onClick: (e: object) => void;
  page: number;
}
type PartialLIProps = Partial<LIProps>;

const maxSequence = 5;
const prefixItems = 2;

const LI = (props: LIProps) => {
  const cls = classNames({
    [s.page]: true,
    [props.className]: props.className,
    [s.disabled]: props.disabled,
  });

  return (
    <li className={cls} data-hook={props.dataHook}>
      {props.notLink ? (
        <span aria-hidden={true}>{props.text}</span>
      ) : (
        <SeoLink
          onClick={props.onClick}
          aria-disabled={props.disabled}
          data-page={props.page}
          data-hook="link"
          to={props.to}
          {...(props.current && { 'aria-current': 'page' })}
          aria-label={props.label}
          className={s.link}
        >
          {props.text}
        </SeoLink>
      )}
    </li>
  );
};

class PaginationCmp extends React.Component<PaginationProps> {
  private handleLinkClick = (event): void => {
    startPaginationInteraction();
    const category = this.props.templatesStore.biCategory || undefined;

    bi.logPaginatingType({
      category,
      page_clicked_index: event.currentTarget && parseInt(event.currentTarget.getAttribute('data-page'), 10),
      pagination_type: 'pagination',
      page_index: this.props.templatesStore.loaded.page,
    });
    this.props.interactionsStore.initInteraction('pagination');
  };

  private getLinks(): LIProps[] {
    const t = this.props.t;
    const totalPages = this.props.templatesStore.totalPages;
    const page = this.props.templatesStore.page;
    const lastPage = totalPages - 1;
    const relativeLinkProvider = this.props.templatesStore.relativeLinkProvider;

    const firstLinks: PartialLIProps[] = [
      {
        dataHook: 'to-first',
        key: 'to-first',
        text: <PaginationLast fill="currentColor" width="1em" height="1em" />,
        label: t('pagination.toFirst'),
        className: s.first,
        to: relativeLinkProvider(0),
        disabled: page === 0,
        onClick: this.handleLinkClick,
        page: 1,
      },
      {
        dataHook: 'prev',
        key: 'prev',
        label: t('pagination.prev'),
        text: <PaginationNext fill="currentColor" width="1em" height="1em" />,
        className: s.prev,
        to: relativeLinkProvider(Math.max(page - 1, 0)),
        page: Math.max(page, 1),
        disabled: page === 0,
        onClick: this.handleLinkClick,
      },
    ];

    const lastLinks: PartialLIProps[] = [
      {
        dataHook: 'next',
        key: 'next',
        text: <PaginationNext fill="currentColor" width="1em" height="1em" />,
        label: t('pagination.next'),
        className: s.next,
        to: relativeLinkProvider(Math.min(page + 1, lastPage)),
        page: Math.min(page + 2, totalPages),
        disabled: page >= lastPage,
        onClick: this.handleLinkClick,
      },
      {
        dataHook: 'to-last',
        key: 'to-last',
        text: <PaginationLast fill="currentColor" width="1em" height="1em" />,
        label: t('pagination.toLast'),
        className: s.last,
        to: relativeLinkProvider(lastPage),
        disabled: page >= lastPage,
        onClick: this.handleLinkClick,
        page: totalPages,
      },
    ];

    const start = Math.max(page - prefixItems, 0);
    const end = Math.min(start + maxSequence, totalPages);

    const numbers: PartialLIProps[] = Array.from(Array(end - start)).map((val, i): PartialLIProps => {
      const num = i + start + 1;
      return {
        text: `${num}`,
        dataHook: `item`,
        key: `page-btn-${num}`,
        label: t('pagination.page', { page: num }),
        page: num,
        disabled: num - 1 === page,
        current: num - 1 === page,
        to: relativeLinkProvider(num - 1),
        onClick: this.handleLinkClick,
      };
    });

    if (end < totalPages - 1) {
      numbers.push({ dataHook: 'ellipsis', text: '...', key: 'ellipsis', notLink: true });
    }
    if (end < totalPages) {
      numbers.push({
        text: `${totalPages}`,
        dataHook: `last-page`,
        key: 'last-page',
        page: totalPages,
        label: t('pagination.page', { page: totalPages }),
        to: relativeLinkProvider(lastPage),
        onClick: this.handleLinkClick,
      });
    }
    const items: PartialLIProps[] = firstLinks.concat(numbers, lastLinks);
    return items as LIProps[];
  }

  private renderHelmet(prev: LIProps, next: LIProps) {
    return (
      <Helmet>
        {!prev.disabled && <link rel="prev" href={this.props.routingStore.routes.toAbsolute(prev.to)} />}
        {!next.disabled && <link rel="next" href={this.props.routingStore.routes.toAbsolute(next.to)} />}
      </Helmet>
    );
  }

  public render() {
    const t = this.props.t;
    const { totalPages } = this.props.templatesStore;

    if (totalPages === undefined) {
      return null;
    }

    if (this.props.templatesStore.totalPages > 1) {
      const items = this.getLinks();
      const prev = items[1];
      const next = items[items.length - 2];
      return (
        <nav data-hook={this.props.dataHook} className={s.pagination} aria-label={t('pagination.label')}>
          {this.renderHelmet(prev, next)}
          <ul data-hook="list" className={s.list}>
            {items.map((item) => (
              <LI {...item} />
            ))}
          </ul>
        </nav>
      );
    }
    return <nav data-hook={this.props.dataHook} className={s.pagination} />;
  }
}

export const Pagination = withTranslations(
  injectStoresV1('templatesStore', 'experimentsStore', 'interactionsStore', 'routingStore')(PaginationCmp),
);
