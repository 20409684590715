import React from 'react';
import { Helmet } from 'react-helmet-async';
import { RoutingStore } from '../../stores/RoutingStore';
import { MetaStore } from '../../stores/MetaStore';
import { ConfigStore } from '../../stores/ConfigStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { generateBreadcrumbListScriptPropsV1 } from './generateBreadcrumbListScriptPropsV1';

export interface HtmlHeadProps extends WithTranslations {
  routingStore: RoutingStore;
  metaStore: MetaStore;
  configStore: ConfigStore;
  categoriesStore: CategoriesStore;
  experimentsStore: ExperimentsStore;
}

const IS_LANDSCAPE_QUERY = `only screen and (orientation: landscape)`;

export const HtmlHeadCmp: React.FC<HtmlHeadProps> = ({
  routingStore,
  configStore,
  metaStore,
  categoriesStore,
  experimentsStore,
  t,
}) => {
  const {
    config: { currentLanguage, domain, supportedLanguages },
  } = configStore;
  const [isLandscape, setIsLandscape] = React.useState(false);
  React.useEffect(() => {
    const handleOrientationChange = (mq: MediaQueryList | MediaQueryListEvent) => {
      setIsLandscape(mq.matches);
    };

    const orientationMedia = window.matchMedia(IS_LANDSCAPE_QUERY);
    orientationMedia.addEventListener('change', handleOrientationChange);
    handleOrientationChange(orientationMedia);

    return () => {
      orientationMedia.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  const { routes, alternateCanonicalLink, canonicalLink, location } = routingStore;
  const mobileViewportWidth = isLandscape ? 640 : 320;
  const isPreviewPage = routingStore.screen === 'preview';
  const canonicalUrl = routes.toAbsolute(canonicalLink ?? location.pathname);
  const generateAlternateUrl = (lang: string = 'en'): string =>
    `https://${lang === 'en' ? 'www' : lang}.${domain}${alternateCanonicalLink}`;
  const breadcrumbListScriptProps = generateBreadcrumbListScriptPropsV1({
    routingStore,
    categoriesStore,
    configStore,
    experimentsStore,
    t,
  });

  return (
    <Helmet script={[breadcrumbListScriptProps]}>
      <title>{metaStore.documentTitle}</title>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@wix" />
      <meta name="twitter:title" content={metaStore.documentTitle} />
      <meta name="twitter:description" content={metaStore.description} />
      <meta
        name="twitter:image:src"
        content={`//static.parastorage.com/services/wix-html-template-viewer/1.1015.0/images/seo/templates-${metaStore.language}.jpg`}
      />

      <meta name="description" content={metaStore.description} />
      <meta
        name="viewport"
        content={`width=${mobileViewportWidth}, user-scalable=no, viewport-fit=cover, shrink-to-fit=no${
          isPreviewPage ? '' : ', initial-scale=1'
        }`}
      />
      <meta name="og:type" content="website" />
      <meta name="og:title" content={metaStore.documentTitle} />
      <meta name="og:description" content={metaStore.description} />
      <meta name="og:url" content={canonicalUrl} />

      {metaStore.noindex && <meta name="robots" content="noindex, nofollow" />}
      {!metaStore.noFollow && [
        <link
          rel="alternate"
          key="x-default"
          hrefLang="x-default"
          href={currentLanguage === 'en' ? canonicalUrl : generateAlternateUrl('en')}
        />,
        <link rel="alternate" key={currentLanguage} hrefLang={currentLanguage} href={canonicalUrl} />,
        ...(metaStore.hrefLangsEnabled
          ? supportedLanguages.map(
              (lang) =>
                currentLanguage !== lang && (
                  <link rel="alternate" key={lang} hrefLang={lang} href={generateAlternateUrl(lang)} />
                ),
            )
          : []),
      ]}
      {!metaStore.noFollow && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  );
};

export const HtmlHead = withTranslations(
  injectStoresV1('routingStore', 'metaStore', 'configStore', 'categoriesStore', 'experimentsStore')(HtmlHeadCmp),
);
