import React from 'react';
import { withTranslations, WithTranslations } from '../../../../utils/withTranslations';
import ArrowLeft from '../../../../Icons/ArrowLeft.svg';
import { injectStoresV1 } from '../../../stores/injectStoresV1';
import { RoutingStore } from '../../../stores/RoutingStore';
import s from './BackToListButton.scss';

interface BackToListButtonProps extends WithTranslations {
  dataHook?: string;
  routingStore: RoutingStore;
}

const BackToListButtonCmp: React.FC<BackToListButtonProps> = ({ t, routingStore, dataHook }) => {
  const handleClick = () => {
    routingStore.goToTemplatesScreen();
  };

  return (
    <button data-hook={dataHook} className={s.button} onClick={handleClick}>
      <ArrowLeft className={s.arrow} />
      <span>{t('template.newMobileFlow.preview.back')}</span>
    </button>
  );
};

export const BackToListButton = withTranslations(injectStoresV1('routingStore')(BackToListButtonCmp));
