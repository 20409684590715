import React, { useEffect, useRef } from 'react';
import cs from 'classnames';
import { withTranslations, WithTranslations } from '../../../utils/withTranslations';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { TemplatePreviewStore } from '../../stores/TemplatePreviewStore';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { Loader } from '../../../components/Loader/Loader';
import { newFlowPreviewDataHooks } from './NewFlowPreview.dataHooks';
import s from './NewFlowPreview.scss';
import { SaveTemplateButton } from './SaveTemplateButton/SaveTemplateButton';
import { BackToListButton } from './BackToListButton/BackToListButton';
import { ReadMoreButton } from './ReadMoreButton/ReadMoreButton';

export interface NewFlowPreviewProps extends WithTranslations {
  dataHook?: string;
  templatesStore: TemplatesStore;
  templatePreviewStore: TemplatePreviewStore;
}

const Fallback = () => (
  <div className={s.loaderWrapper}>
    <Loader dataHook={newFlowPreviewDataHooks.loader()} />
  </div>
);

const NewFlowPreviewCmp: React.FC<NewFlowPreviewProps> = ({ dataHook, templatesStore, templatePreviewStore }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const title = `Preview of website ${templatesStore.expandedTemplate?.title}`;

  useEffect(() => {
    const handleFrameDomContentLoaded = () => {
      setIsLoading(false);
    };
    const currentIframe = iframeRef.current;

    if (templatePreviewStore.previewIframeUrl) {
      setIsLoading(true);
    }
    if (currentIframe) {
      currentIframe.contentWindow?.addEventListener('DOMContentLoaded', handleFrameDomContentLoaded);
      return () => {
        currentIframe.contentWindow?.removeEventListener('DOMContentLoaded', handleFrameDomContentLoaded);
      };
    }
  }, [templatePreviewStore.previewIframeUrl]);

  return (
    <section className={s.container} data-hook={dataHook}>
      <header className={s.header}>
        <BackToListButton dataHook={newFlowPreviewDataHooks.backToListButton()} />
        <SaveTemplateButton dataHook={newFlowPreviewDataHooks.saveTemplateButton()} />
      </header>
      <div className={s.frameWrapper}>
        <iframe
          ref={iframeRef}
          title={title}
          data-hook={newFlowPreviewDataHooks.iframe()}
          src={templatePreviewStore.previewIframeUrl}
          key={templatesStore.expandedTemplate?.id}
          className={cs(s.frame, { [s.frameLoading]: isLoading })}
        />
        {isLoading && <Fallback />}
      </div>
      <footer className={s.footer}>
        <ReadMoreButton dataHook={newFlowPreviewDataHooks.readMoreButton()} />
      </footer>
    </section>
  );
};

export const NewFlowPreview = withTranslations(
  injectStoresV1('templatePreviewStore', 'templatesStore')(NewFlowPreviewCmp),
);
