import React, { MouseEvent } from 'react';
import { autorun } from 'mobx';
import { TemplatePreviewStore } from '../../stores/TemplatePreviewStore';
import { RoutingStore } from '../../stores/RoutingStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { getLogger } from '../../utils/fedops-logger';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import s from './Preview.scss';

export interface PreviewProps extends WithTranslations {
  dataHook?: string;
  templatePreviewStore: TemplatePreviewStore;
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
}

export class PreviewComp extends React.Component<PreviewProps> {
  constructor(props: PreviewProps) {
    super(props);
    autorun(() => {
      !this.props.templatePreviewStore.isPreviewOrDetailsScreen &&
        this.props.templatePreviewStore.setIframeLoaded(false);
      PreviewComp.endPreviewInteraction(this.props);
    });
  }
  private handlePreviewLoad = (e): void => {
    this.props.templatePreviewStore.setIframeLoaded(!!e.target.src);
  };

  private static endPreviewInteraction(props: PreviewProps): void {
    props.routingStore.screen === 'preview' &&
      props.templatePreviewStore.isIframeLoaded &&
      getLogger().interactionEnded('mobile-preview');
  }

  private handleBackClick = (e: MouseEvent): void => {
    e.preventDefault();
    this.props.routingStore.goToTemplatesScreen();
  };

  public render() {
    const title = `Preview of website ${this.props.templatesStore.expandedTemplate?.title}`;
    const previewSrc = this.props.templatePreviewStore.previewIframeUrl;

    return (
      <div data-hook={this.props.dataHook} className={s.preview}>
        <div className={s.frameScaler}>
          <iframe
            title={title}
            onLoad={this.handlePreviewLoad}
            className={s.frame}
            data-hook="previewFrame"
            src={previewSrc}
            key={this.props.templatesStore.expandedTemplate?.id}
          />
        </div>
        <div className={s.backLinkWrapper}>
          <span role="link" className={s.backLink} data-hook="backLink" onClick={this.handleBackClick}>
            {this.props.t('template.preview.back')}
          </span>
        </div>
      </div>
    );
  }
}

export const Preview = withTranslations(
  injectStoresV1(
    'templatePreviewStore',
    'routingStore',
    'templatesStore',
  )(PreviewComp as React.ComponentClass<PreviewProps>),
);
