import { computed, makeObservable } from 'mobx';
import { TemplateModel } from '../model/TemplateModel';
import { ApiClient } from '../../apiClient/ApiClient';
import { TemplatesStore } from './TemplatesStore';
import { RoutingStore } from './RoutingStore';

export class ThankYouStore {
  constructor(
    private templatesStore: TemplatesStore,
    private routingStore: RoutingStore,
    private apiClient: ApiClient,
  ) {
    makeObservable<ThankYouStore>(this, { template: computed });
  }

  get template(): TemplateModel | undefined {
    const { templateSlug } = this.routingStore;
    if (!templateSlug) {
      return undefined;
    }

    return this.templatesStore.templates.find(({ id }) => templateSlug === id);
  }

  sendEmail(esid: string) {
    return this.apiClient.templateMailerSend({
      templateSlug: this.routingStore.templateSlug,
      esid,
    });
  }
}
