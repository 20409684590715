import { v4 as uuidv4 } from 'uuid';
import * as bi from '../utils/BILogger';

export type InteractionType = 'search' | 'pagination' | 'category_switch' | 'landing';
type State = 'initial' | 'started' | 'loaded' | 'rendered';

class Interaction {
  public interaction_type: InteractionType;
  public key: string;
  private startTime: number;
  private state: State = 'initial';

  constructor(interactionType: InteractionType, startTime?: number) {
    this.interaction_type = interactionType;
    this.key = uuidv4();

    if (startTime) {
      this.startTime = startTime;
      this.state = 'started';
    }
  }

  public reportLoadingStart(params: Omit<bi.TemplatesStartedLoadingBI, 'interaction_type'>): Promise<void> {
    if (this.state !== 'initial') {
      return Promise.resolve();
    }
    this.startTime = Date.now();
    this.state = 'started';
    return bi.logTemplatesStartLoading({
      ...params,
      interaction_type: this.interaction_type,
    });
  }

  public reportLoadingEnd(
    params: Omit<bi.TemplatesFinishedLoadingBI, 'interaction_type' | 'loadingTime'>,
  ): Promise<void> {
    if (this.state !== 'started') {
      return Promise.resolve();
    }
    this.state = 'loaded';
    return bi.logTemplatesFinishLoading({
      ...params,
      loadingTime: Date.now() - this.startTime,
      interaction_type: this.interaction_type,
    });
  }

  public reportPageRendered(
    params: Omit<bi.TemplatesFinishedRenderingBI, 'interaction_type' | 'loadingTime'>,
  ): Promise<void> {
    if (this.state !== 'loaded') {
      return Promise.resolve();
    }
    this.state = 'rendered';
    return bi.logTemplatesFullyRendered({
      ...params,
      loadingTime: Date.now() - this.startTime,
      interaction_type: this.interaction_type,
    });
  }
}

export class InteractionsStore {
  private interactions: Map<string, Interaction> = new Map();
  private lastInteractionKey: string = null;

  initInteraction(interactionType: InteractionType, startTime?: number) {
    const interaction = new Interaction(interactionType, startTime);
    this.lastInteractionKey = interaction.key;
    this.interactions.set(interaction.key, interaction);
    return interaction;
  }

  getType(key: string): string {
    const interaction = this.interactions.get(key);
    return interaction && interaction.interaction_type;
  }

  getLastInteractionKey() {
    return this.lastInteractionKey;
  }

  reportLoadingStart(key: string, params): Promise<any> {
    const interaction = this.interactions.get(key);
    return interaction ? interaction.reportLoadingStart(params) : Promise.resolve();
  }

  reportLoadingEnd(key: string, params) {
    const interaction = this.interactions.get(key);
    return interaction ? interaction.reportLoadingEnd(params) : Promise.resolve();
  }

  reportPageRendered(key: string, params) {
    const interaction = this.interactions.get(key);

    if (!interaction) {
      return Promise.resolve();
    }

    this.interactions.delete(key);
    if (key === this.lastInteractionKey) {
      this.lastInteractionKey = null;
    }

    return interaction.reportPageRendered(params);
  }
}
