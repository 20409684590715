import { TemplatePrice } from '@wix/ambassador-funnel-templates-gallery-v2-template/types';
import { Template } from '../../web-api/domain/template';

export class TemplateModel {
  id: string;
  title: string;
  previewImg: string;
  mobileImg: string;
  templatePrice: TemplatePrice;
  info: {
    goodFor: string;
    description: string;
  };
  siteId: string;
  metaSiteId: string;
  score?: number;

  constructor({
    id = '',
    title = '',
    previewImg = '',
    mobileImg = '',
    templatePrice,
    info = {
      goodFor: null,
      description: null,
    },
    siteId = '',
    metaSiteId = '',
    score,
  }: Partial<Template>) {
    this.id = id;
    this.title = title;
    this.previewImg = previewImg;
    this.mobileImg = mobileImg;
    this.templatePrice = templatePrice;
    this.siteId = siteId;
    this.metaSiteId = metaSiteId;
    this.score = score;
    this.info = {
      goodFor: info.goodFor,
      description: info.description,
    };
  }

  static fromDTO(template: Template) {
    return new TemplateModel(template);
  }

  static toDTO(model: TemplateModel): Template {
    return {
      id: model.id,
      siteId: model.siteId,
      title: model.title,
      previewImg: model.previewImg,
      mobileImg: model.mobileImg,
      templatePrice: model.templatePrice,
      metaSiteId: model.metaSiteId,
      info: { ...model.info },
      score: model.score,
    };
  }
}
