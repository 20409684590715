import React from 'react';
import cs from 'classnames';
import ArrowRight from '../../../Icons/ArrowRight.svg';
import { ConfigStore } from '../../stores/ConfigStore';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import s from './MobileToggler.scss';

export interface MobileTogglerProps extends WithTranslations {
  dataHook?: string;
  configStore: ConfigStore;
  onToggle?: React.MouseEventHandler<HTMLLabelElement>;
}

const MobileTogglerCmp: React.FC<MobileTogglerProps> = ({ dataHook = 'mobileToggler', onToggle, configStore, t }) => {
  const isSingleHeader = configStore.config.currentLanguage !== 'en';

  return (
    <div className={cs(s.box, { [s.singleHeader]: isSingleHeader })} data-hook={dataHook}>
      <label data-hook="toggler" htmlFor="mobile-screen-navigation" className={s.button} onClick={onToggle}>
        <span>{t('sideBar.toggle.browseCategories')}</span>
        <ArrowRight fill="currentColor" width="1em" className={s.arrow} />
      </label>
    </div>
  );
};

export const MobileToggler = withTranslations(injectStoresV1('configStore')(MobileTogglerCmp));
