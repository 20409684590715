import className from 'classnames';
import React from 'react';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { Category } from './Category/Category';
import s from './Categories.scss';

export interface CategoriesProps {
  dataHook?: string;
  className?: string;
  categoriesStore: CategoriesStore;
  labeledBy?: string;
  onChange?: Function;
  seqClassName?: string;
  seqStart?: number;
  itemClassName?: string;
  seqClasses?: { [key: string]: string };
  setActiveRef?: (element: HTMLElement) => void;
}

export const Categories = injectStoresV1('categoriesStore')((props: CategoriesProps) => {
  props.onChange && props.onChange();
  const { categoriesStore, setActiveRef } = props;
  const categoryHook = 'category';
  const seqStart = props.seqStart || 0;
  const activeCategory = !categoriesStore.activeSubCategory && categoriesStore.activeCategory;
  const expandedCategories = categoriesStore.expandedCategories;
  const hasSubCategories = categoriesStore.activeCategory?.items.length > 0;
  const cssClasses = className(s.list, {
    [props.className]: props.className,
    [s.hasSubCategories]: hasSubCategories,
  });

  return (
    <ul aria-labelledby={props.labeledBy} className={cssClasses} data-hook={props.dataHook}>
      {categoriesStore.categories.map((category, index) => (
        <Category
          key={category.id}
          dataHook={categoryHook}
          setActiveRef={setActiveRef}
          expanded={expandedCategories.includes(category)}
          isActive={category === activeCategory}
          categoryId={category.id}
          title={category.title}
          items={category.items}
          className={className(s.listItem, {
            [s.expanded]: hasSubCategories && categoriesStore.activeCategory === category,
            [s.allLink]: category.id === 'all',
            [props.itemClassName]: props.itemClassName,
            [props.seqClasses && props.seqClasses[`${props.seqClassName}${seqStart + index}`]]:
              props.seqClasses && props.seqClassName,
          })}
        />
      ))}
    </ul>
  );
});
