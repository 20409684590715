import React from 'react';
import { useLocation } from 'react-router-dom';
import { SideBar } from '../SideBar/SideBar';
import { Templates } from '../Templates/Templates';
import { RoutingStore } from '../../stores/RoutingStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { MetaStore } from '../../stores/MetaStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { Pagination } from '../Pagination/Pagination';
import { HtmlHead } from '../HtmlHead/HtmlHead';
import { MobileToggler } from '../MobileToggler/MobileToggler';
import { MobileTemplatesHeading } from '../MobileTemplatesHeading/MobileTemplatesHeading';
import { Preview } from '../Preview/Preview';
import * as bi from '../../utils/BILogger';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { MobileHeader } from '../MobileHeader/MobileHeader';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { SeoSection } from '../SeoSection/SeoSection';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { NewFlowPreview } from '../NewFlowPreview/NewFlowPreview';
import { ThankYou } from '../ThankYou/ThankYou';
import { useSidebarStateContext } from '../../contexts/SidebarStateContext';
import { MicroCategoriesTags } from '../MicroCategories/MicroCategoriesTags';
import s from './App.scss';

const CBD_CATEGORY_SLUG = 'cbd';

export interface AppProps extends WithTranslations {
  onNavigation?: () => void;
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
  metaStore: MetaStore;
  categoriesStore: CategoriesStore;
  experimentsStore: ExperimentsStore;
}

export const AppCmp: React.FC<AppProps> = ({
  templatesStore,
  categoriesStore,
  experimentsStore,
  routingStore,
  onNavigation,
}) => {
  React.useEffect(() => {
    const handleBiElementClick = (event: MouseEvent): void => {
      const { page: page_index } = templatesStore;
      const { activeCategory } = categoriesStore;
      const category = activeCategory ? activeCategory.id : '';
      const target = event.target as HTMLElement;

      const element = target.closest('[data-bi-element]');
      const galleryDocIndexElement = target.closest('[data-bi-gallery-doc-index]');
      const gallerydocindex = galleryDocIndexElement
        ? galleryDocIndexElement.getAttribute('data-bi-gallery-doc-index')
        : 'NULL';
      const clicked_element = element ? element.getAttribute('data-bi-element') : 'other';
      const clicked_element_identifier = element ? element.getAttribute('data-bi-element-value') : 'NULL';

      void bi.logClickOnElements({
        clicked_element,
        clicked_element_identifier,
        gallerydocindex,
        category,
        page_index,
      });
    };

    document.body.addEventListener('click', handleBiElementClick);
    return () => {
      document.body.removeEventListener('click', handleBiElementClick);
    };
  }, []);

  const mainRef = React.useRef<HTMLElement>();
  const headerRef = React.useRef<HTMLElement>();
  const setMainRef = (element: HTMLElement) => (mainRef.current = element);
  const setHeaderRef = (element: HTMLElement) => (headerRef.current = element);
  const activePage = React.useRef<string | null>(null);
  const routeLocation = useLocation<{ shouldNotCloseSidebar: boolean }>();
  const isEnabledMicroCategories =
    experimentsStore.is('specs.funnel.MarketingTemplatesMicroCategories', 'true') &&
    experimentsStore.is('specs.funnel.TemplatesBulkandMicrocatsEN', 'true');

  const { screen } = routingStore;
  const { isSidebarOpen, openSidebar, closeSidebar } = useSidebarStateContext();
  React.useEffect(() => {
    const disableBodyScroll = ['details'].includes(screen) || isSidebarOpen;
    const disableBodyScrollOnPreviewPage = ['preview'].includes(screen);

    document.body.classList.toggle(s.disableBodyScrollOnPreviewPage, disableBodyScrollOnPreviewPage);
    document.body.classList.toggle(s.disableBodyScroll, disableBodyScroll);
  }, [screen, isSidebarOpen]);

  React.useEffect((): void => {
    const newActivePage = templatesStore.loaded.url;
    if (newActivePage !== activePage.current) {
      if (!routeLocation.state?.shouldNotCloseSidebar) {
        closeSidebar();
      }

      if (typeof mainRef.current?.scroll === 'function') {
        mainRef.current.scroll(0, 0);
      }

      if (typeof headerRef.current?.getBoundingClientRect === 'function') {
        const { height: headerHeight } = headerRef.current.getBoundingClientRect();
        const isInitialRender = activePage.current === null;

        if (!isInitialRender) {
          window.scroll(0, headerHeight);
        }
      }

      activePage.current = newActivePage;
      onNavigation && onNavigation();
    }
  }, [templatesStore.loaded.url]);

  const isNewMobileFlow = experimentsStore.is('specs.funnel.TemplatesGalleryNewMobileFlow', 'true');
  if (isNewMobileFlow && routingStore.screen === 'thank-you') {
    return <ThankYou />;
  }

  const handleOpenSidebar = (event: React.MouseEvent<HTMLLabelElement>): void => {
    if (event.type !== 'change') {
      event.preventDefault();
    }

    openSidebar();
  };

  const isCbdBannerEnabled = categoriesStore?.activeSubCategory?.id === CBD_CATEGORY_SLUG;

  return (
    <div className={s.appWrapper} data-hook="app">
      <div className={s.appContent}>
        <HtmlHead />
        <input
          id="mobile-screen-navigation"
          className={s.mobileSwitcher}
          type="radio"
          data-hook="mobile-screen"
          name="mobile-screen"
          value="navigation"
          checked={isSidebarOpen}
          readOnly
        />
        <input
          className={s.mobileSwitcher}
          type="radio"
          data-hook="mobile-screen"
          name="mobile-screen"
          value="templates"
          checked={!isSidebarOpen && screen === 'templates'}
          readOnly
        />
        <input
          className={s.mobileSwitcher}
          type="radio"
          data-hook="mobile-screen"
          name="mobile-screen"
          value="details"
          checked={!isSidebarOpen && screen === 'details'}
          readOnly
        />
        <input
          className={s.mobileSwitcher}
          type="radio"
          data-hook="mobile-screen"
          name="mobile-screen"
          value="preview"
          checked={!isSidebarOpen && screen === 'preview'}
          readOnly
        />
        <SideBar id="sideBar" dataHook="sideBar" hidden={!isSidebarOpen} onCloseSidebar={closeSidebar} />
        <main className={s.main} ref={setMainRef}>
          <MobileHeader forwardRef={setHeaderRef} />
          <MobileToggler onToggle={handleOpenSidebar} dataHook="sideBar-toggler" />
          <MobileTemplatesHeading />
          <Templates isCbdBannerEnabled={isCbdBannerEnabled} />
          <Pagination dataHook="pagination" />
          {isEnabledMicroCategories && <MicroCategoriesTags />}
          <SeoSection dataHook="seoSection" />
        </main>
        {isNewMobileFlow ? <NewFlowPreview dataHook="preview" /> : <Preview dataHook="preview" />}
      </div>
    </div>
  );
};

export const App = withTranslations(
  injectStoresV1('routingStore', 'templatesStore', 'metaStore', 'categoriesStore', 'experimentsStore')(AppCmp),
);
