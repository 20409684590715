import cs from 'classnames';
import React from 'react';
import { SeoLink } from '../../SeoLink/SeoLink';
import s from '../Categories.scss';

export interface SubCategoryProps {
  className: string;
  dataHook: string;
  to: string;
  title: string;
  isActive?: boolean;
  onClick?: () => void;
  setRef?: (element: HTMLElement) => void;
}

export class SubCategory extends React.Component<SubCategoryProps> {
  render() {
    return (
      <li
        ref={this.props.setRef}
        data-hook={this.props.dataHook}
        className={this.props.className}
        data-bi-element="category"
        data-bi-element-value={this.props.title}
      >
        <SeoLink
          data-hook="link"
          className={cs(s.link, { [s.active]: this.props.isActive })}
          to={this.props.to}
          onClick={this.props.onClick}
          aria-current={this.props.isActive}
        >
          {this.props.title}
        </SeoLink>
      </li>
    );
  }
}
