import React from 'react';
import { DataCapsule } from '@wix/data-capsule';

export const dataCapsuleOptions = {
  scope: 'template',
  category: 'functional',
};

export const SavedTemplateContext = React.createContext({
  setSavedTemplateEditorSessionId: (templateSlug: string, editorSessionId: string) => Promise.resolve(),
  getSavedTemplateEditorSessionId: (templateSlug: string) => Promise.resolve(''),
  deleteSavedTemplateEditorSessionId: (templateSlug: string) => Promise.resolve(),
});

export const useSavedTemplate = () => React.useContext(SavedTemplateContext);

export const SavedTemplateProvider: React.FC<{ dataCapsule: DataCapsule }> = ({ children, dataCapsule }) => {
  return (
    <SavedTemplateContext.Provider
      value={{
        setSavedTemplateEditorSessionId: (templateSlug: string, editorSessionId: string) =>
          dataCapsule.setItem(templateSlug, editorSessionId, dataCapsuleOptions),
        getSavedTemplateEditorSessionId: (templateSlug: string) =>
          dataCapsule.getItem(templateSlug, dataCapsuleOptions),
        deleteSavedTemplateEditorSessionId: (templateSlug: string) =>
          dataCapsule.removeItem(templateSlug, dataCapsuleOptions),
      }}
    >
      {children}
    </SavedTemplateContext.Provider>
  );
};
