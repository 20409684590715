import React, { FC, useCallback, useMemo } from 'react';

import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { RoutingStore } from '../../stores/RoutingStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { biLogger } from '../../utils/BILogger';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { TagLink } from '../TagLink/TagLink';
import { useScrollToTop } from '../../../v2/contexts/ScrollToTopContext';
import styles from './MicroCategoriesTags.scss';

export type MicroCategoriesTagsProps = {
  categoriesStore: CategoriesStore;
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
} & WithTranslations;

const MicroCategoriesTagsCmp: FC<MicroCategoriesTagsProps> = (props) => {
  const { scrollToTop } = useScrollToTop();
  const {
    t,
    routingStore,
    categoriesStore: { intentCategories },
    templatesStore,
  } = props;
  const { categorySlug, subCategorySlug, routes } = routingStore;

  const microCategories = useMemo(
    () => (intentCategories ?? []).find(({ id }) => id === (subCategorySlug || categorySlug))?.items ?? [],
    [categorySlug, intentCategories, subCategorySlug],
  );

  const onHandleBI = useCallback(
    ({ microCategory, selectedValue }) => {
      biLogger.logMicroCategoryClick({
        microCategory,
        pageIndex: templatesStore.page,
        selectedValue,
        subCategory: subCategorySlug,
        category: categorySlug,
      });
    },
    [categorySlug, subCategorySlug, templatesStore.page],
  );

  if (!microCategories?.length) {
    return null;
  }

  return (
    <div className={styles.MicroCategoriesTags}>
      <span className={styles.MicroCategoriesTags__title}>{t('microCategories.title')}</span>
      <div className={styles.MicroCategoriesTags__tasList}>
        {microCategories.map(({ id, title }) => (
          <TagLink
            title={title}
            key={id}
            onClick={() => {
              scrollToTop();
              onHandleBI({ microCategory: id, selectedValue: title });
            }}
            to={routes.microCategoryRoute.get(id)}
          />
        ))}
      </div>
    </div>
  );
};

export const MicroCategoriesTags = withTranslations(
  injectStoresV1('categoriesStore', 'routingStore', 'templatesStore')(MicroCategoriesTagsCmp),
);
