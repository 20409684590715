import React, { useEffect, useState } from 'react';
import { withTranslations, WithTranslations } from '../../../utils/withTranslations';
import { TemplateThumbnail } from '../TemplateThumbnail/TemplateThumbnail';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { ThankYouStore } from '../../stores/ThankYouStore';
import { RoutingStore } from '../../stores/RoutingStore';
import { HtmlHead } from '../HtmlHead/HtmlHead';
import { SeoLink } from '../SeoLink/SeoLink';
import { useSavedTemplate } from '../../contexts/SavedTemplateContext';
import ClosePopup from '../../../Icons/ClosePopup.svg';
import { Loader } from '../../../components/Loader/Loader';
import { ConfigStore } from '../../../v2/stores/ConfigStore';
import { biLogger } from '../../utils/BILogger';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import s from './ThankYou.scss';
import { thankYouDataHooks } from './ThankYou.dataHooks';
import { ThankYouPageError } from './ThankYouPageError';
import { WixOwnerAppBanner } from './WixOwnerAppBanner/WixOwnerAppBanner';

export interface ThankYouProps extends WithTranslations {
  dataHook?: string;
  thankYouStore: ThankYouStore;
  routingStore: RoutingStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
}

const ThankYouCmp: React.FC<ThankYouProps> = ({ t, thankYouStore, routingStore, configStore, experimentsStore }) => {
  const { loggedIn } = configStore.config.identity;
  const category = routingStore.searchQuery || routingStore.categorySlug;
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { template } = thankYouStore;

  const savedTemplate = useSavedTemplate();
  const imgAlt = t('template.previewAlt', { category, template: template?.title });

  const isSendEmailViaBiQuery = experimentsStore.is('specs.funnel.TemplatesGallerySendEmailViaBiQuery', 'true');

  useEffect(() => {
    let isMounted = true;
    const encodedUrl = encodeURIComponent(window.location.href);
    const signInUrl = `https://users.wix.com/signin?view=sign-up&loginCompName=SignUp_H&postSignUp=${encodedUrl}&postLogin=${encodedUrl}&forceRender=true`;

    if (!loggedIn) {
      return window.location.assign(signInUrl);
    }

    const sendEmail = async () => {
      try {
        const savedEditorSessionId = await savedTemplate.getSavedTemplateEditorSessionId(template.id);
        await thankYouStore.sendEmail(savedEditorSessionId);
        await savedTemplate.deleteSavedTemplateEditorSessionId(template.id);

        if (isMounted) {
          setIsEmailSent(true);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
        }
      }
    };

    if (isSendEmailViaBiQuery) {
      setIsEmailSent(true);
    } else {
      void sendEmail();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  if (error) {
    return (
      <div className={s.page}>
        <ThankYouPageError mainLink={routingStore.templateScreenURL} />
        <SeoLink
          to={routingStore.templateScreenURL}
          className={s.closeButton}
          data-hook={thankYouDataHooks.closeButton()}
        >
          <ClosePopup className={s.closeIcon} />
        </SeoLink>
      </div>
    );
  }

  return (
    <div className={s.page}>
      <HtmlHead />
      <div className={s.content}>
        {isEmailSent || isSendEmailViaBiQuery ? (
          <>
            <div className={s.images}>
              <div className={s.desktopImageWrapper}>
                <div className={s.desktopImageFrame}>
                  <div className={s.desktopImageFrameDots}>
                    <div className={s.desktopImageFrameDot} />
                    <div className={s.desktopImageFrameDot} />
                    <div className={s.desktopImageFrameDot} />
                  </div>
                </div>
                <TemplateThumbnail
                  className={s.desktopImage}
                  alt={imgAlt}
                  ariaLabel={t('template.previewImg', { template: template.title })}
                  imagePath={template.previewImg}
                  dataHook={thankYouDataHooks.desktopPicture()}
                />
              </div>
              <div className={s.mobileImageWrapper}>
                <TemplateThumbnail
                  className={s.mobileImage}
                  alt={imgAlt}
                  ariaLabel={t('template.mobilePreviewImg', { template: template.title })}
                  imagePath={template.mobileImg}
                  dataHook={thankYouDataHooks.mobilePicture()}
                />
                <div className={s.mobileImageFooter} />
              </div>
            </div>
            <div className={s.title} data-hook={thankYouDataHooks.title()}>
              {isSendEmailViaBiQuery ? t('thankYou.success.alternativeTitle') : t('thankYou.success.title')}
            </div>
            <div className={s.description}>{t('thankYou.success.description')}</div>
            <SeoLink
              to={routingStore.templateScreenURL}
              className={s.ctaButton}
              data-hook={thankYouDataHooks.ctaButton()}
              replace
              onClick={() => {
                biLogger.logActionOnThankYouPage('back-to-gallery');
              }}
            >
              {t('thankYou.success.cta')}
            </SeoLink>
            <WixOwnerAppBanner dataHook={thankYouDataHooks.wixOwnerAppBanner()} />
          </>
        ) : (
          <div className={s.loaderWrapper}>
            <Loader dataHook={thankYouDataHooks.loader()} />
          </div>
        )}
      </div>
      <SeoLink
        to={routingStore.templateScreenURL}
        className={s.closeButton}
        data-hook={thankYouDataHooks.closeButton()}
        replace
        onClick={() => {
          biLogger.logActionOnThankYouPage('close');
        }}
      >
        <ClosePopup className={s.closeIcon} />
      </SeoLink>
    </div>
  );
};

export const ThankYou = withTranslations(
  injectStoresV1('thankYouStore', 'routingStore', 'configStore', 'experimentsStore')(ThankYouCmp),
);
