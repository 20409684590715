import React, { FC } from 'react';
import s from './Loader.scss';

interface LoaderProps {
  dataHook?: string;
}

export const Loader: FC<LoaderProps> = ({ dataHook }) => (
  <div className={s.root} data-hook={dataHook}>
    <div className={s.arcsContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-51 -51 102 102" className={s.lightArc}>
        <path strokeWidth="4" d="M 3.0003846579110155e-15 49 A 49 49 0 0 0 3.0003846579110155e-15 -49" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-51 -51 102 102" className={s.darkArc}>
        <path strokeWidth="4" d="M 3.0003846579110155e-15 49 A 49 49 0 0 0 3.0003846579110155e-15 -49" />
      </svg>
    </div>
  </div>
);
