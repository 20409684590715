import React from 'react';
import className from 'classnames';
import initSticky from 'sticky-position';
import { autorun } from 'mobx';
import { CollapseButton } from '../ToggleIcon/ToggleIcon';
import { Search } from '../Search/Search';
import { Categories } from '../Categories/Categories';
import { Filters } from '../Filters/Filters';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import s from './SideBar.scss';

export interface SideBarProps extends WithTranslations {
  dataHook?: string;
  categoriesStore: CategoriesStore;
  templatesStore: TemplatesStore;
  hidden?: boolean;
  id?: string;
  experimentsStore: ExperimentsStore;
  onCloseSidebar: () => void;
}

const defaultProps = {
  hidden: false,
};

class SideBarCmp extends React.Component<SideBarProps, null> {
  private stickyRefs: {
    placeholder: HTMLElement;
    primary: HTMLElement;
    wrapper: HTMLElement;
  } = {
    primary: null,
    placeholder: null,
    wrapper: null,
  };
  private stickyNav;
  private activePage;
  private unsubscribeNavigation: () => void;
  private activeRef: HTMLElement;

  private handleChildrenUpdate = () => {
    this.stickyNav && this.stickyNav.update();
  };

  public componentDidMount() {
    this.stickyNav = initSticky(this.stickyRefs);
    this.unsubscribeNavigation = autorun(this.handleNavigation);
    this.scrollToActiveElement();
  }

  public componentWillUnmount() {
    this.unsubscribeNavigation && this.unsubscribeNavigation();
    this.stickyNav && this.stickyNav.destroy();
  }

  private static isOutOfScreen(element: HTMLElement) {
    const { y, height } = element.getBoundingClientRect();
    return y + height > window.innerHeight;
  }

  private setActiveRef = (activeRef) => {
    this.activeRef = activeRef;
  };

  private scrollToActiveElement() {
    // return scrollRestoration to manual for chrome
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    const nodeToScroll = this.activeRef;
    if (nodeToScroll && SideBarCmp.isOutOfScreen(nodeToScroll)) {
      try {
        nodeToScroll.scrollIntoView({ block: 'center' });
      } catch (e) {
        nodeToScroll.scrollIntoView(false);
      }
    }
  }

  private handleNavigation = () => {
    const newActivePage: string = this.props.templatesStore.loaded.url;
    if (newActivePage !== this.activePage) {
      this.activePage = this.props.templatesStore.loaded.url;
      if (this.stickyRefs.primary) {
        this.stickyNav && this.stickyNav.update();
      }
    }
  };

  private handleCloseSidebar = (event: React.MouseEvent<HTMLLabelElement>): void => {
    if (event.type !== 'change') {
      event.preventDefault();
    }

    this.props.onCloseSidebar();
  };

  public render() {
    const { t } = this.props;
    const props: SideBarProps = Object.assign(defaultProps, this.props);
    const activeCategory = this.props.categoriesStore.activeCategory;

    const classNames = className(s.sideBar, {
      [s.inCategory]: activeCategory && activeCategory.id !== 'all' && activeCategory.items.length > 0,
    });

    this.stickyNav && this.stickyNav.update();

    return (
      <div data-hook={props.dataHook} aria-hidden={props.hidden} className={classNames} id={props.id}>
        <div className={s.wrapper} ref={(wrapper) => (this.stickyRefs.wrapper = wrapper)}>
          <nav
            aria-label={t('sideBar.label')}
            className={s.content}
            role="navigation"
            ref={(nav) => (this.stickyRefs.primary = nav)}
          >
            <Search dataHook="search" />
            <div className={className(s.heading, s.sliding, s[`animationSequence${0}`])} id="sidebar-viewBy-title">
              {t('sideBar.viewBy')}
            </div>
            <Filters
              setActiveRef={this.setActiveRef}
              itemClassName={s.sliding}
              seqClasses={s}
              seqClassName="animationSequence"
              seqStart={1}
              labeledBy="sidebar-viewBy-title"
              className={s.filters}
            />
            <div
              className={className(
                s.heading,
                s.sliding,
                s[`animationSequence${props.categoriesStore.filters.length + 1}`],
              )}
              id="sidebar-categories-title"
            >
              {t('sideBar.categories')}
            </div>
            <Categories
              dataHook="categories"
              setActiveRef={this.setActiveRef}
              itemClassName={s.sliding}
              seqClasses={s}
              seqClassName="animationSequence"
              seqStart={props.categoriesStore.filters.length + 2}
              labeledBy="sidebar-categories-title"
              onChange={this.handleChildrenUpdate}
              className={s.categories}
            />
          </nav>
          <CollapseButton dataHook="mobile-close-navigation-handler" onToggle={this.handleCloseSidebar} />
          <div className={s.dummy} ref={(dummy) => (this.stickyRefs.placeholder = dummy)} />
        </div>
      </div>
    );
  }
}

export const SideBar = withTranslations(
  injectStoresV1('categoriesStore', 'templatesStore', 'experimentsStore')(SideBarCmp),
);
