import React from 'react';
import ErrorUnavailableIcon from '../../../Icons/ErrorUnavailable.svg';
import { withTranslations, WithTranslations } from '../../../utils/withTranslations';
import { SeoLink } from '../SeoLink/SeoLink';
import { thankYouDataHooks } from './ThankYou.dataHooks';
import s from './ThankYou.scss';

interface ThankYouPageErrorProps extends WithTranslations {
  dataHook?: string;
  mainLink: string;
}

export const ThankYouPageErrorCmp: React.FC<ThankYouPageErrorProps> = ({ mainLink, t }) => (
  <div className={s.content}>
    <div className={s.iconContainer}>
      <ErrorUnavailableIcon />
    </div>
    <div className={s.title}>{t('thankYou.error.title')}</div>
    <div className={s.description}>{t('thankYou.error.description')}</div>
    <SeoLink to={mainLink} className={s.ctaButton} data-hook={thankYouDataHooks.errorCtaButton()}>
      {t('thankYou.error.cta')}
    </SeoLink>
  </div>
);

export const ThankYouPageError = withTranslations(ThankYouPageErrorCmp);
