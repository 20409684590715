import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface Props {
  children: (args: { isAppear: boolean }) => React.ReactNode;
}

export const AppearSensor = ({ children }: Props) => {
  const [hasBeenVisible, setHasBeenVisible] = useState(false);

  return (
    <VisibilitySensor
      partialVisibility={true}
      active={!hasBeenVisible}
      onChange={(isVisible) => {
        if (isVisible) {
          setHasBeenVisible(true);
        }
      }}
    >
      {({ isVisible }) => children({ isAppear: isVisible || hasBeenVisible })}
    </VisibilitySensor>
  );
};
