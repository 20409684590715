import React from 'react';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { CategoriesStore } from '../../stores/CategoriesStore';
import s from './MobileHeader.scss';

export interface MobileHeaderProps extends WithTranslations {
  categoriesStore: CategoriesStore;
  forwardRef?: React.RefCallback<HTMLHeadingElement>;
}

const MobileHeaderCmp: React.FC<MobileHeaderProps> = (props) => {
  const { t, forwardRef, categoriesStore } = props;
  const { activeCategory } = categoriesStore;

  const TagName: 'span' | 'h1' = activeCategory?.id === 'all' ? 'h1' : 'span';

  return (
    <header className={s.header} ref={forwardRef}>
      <TagName data-hook="mobile-header-text" className={s.text}>
        {t('header.title.v2')}
      </TagName>
    </header>
  );
};

export const MobileHeader = withTranslations(injectStoresV1('categoriesStore')(MobileHeaderCmp));
