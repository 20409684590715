import cs from 'classnames';
import React from 'react';
import Expander from '../../../../Icons/Expander.svg';
import BackArrow from '../../../../Icons/BackArrow.svg';
import { injectStoresV1 } from '../../../stores/injectStoresV1';
import { CategoryModel } from '../../../model/CategoryModel';
import { CategoriesStore } from '../../../stores/CategoriesStore';
import { InteractionsStore } from '../../../stores/InteractionsStore';
import { RoutingStore } from '../../../stores/RoutingStore';
import { startCategoryInteraction } from '../../../utils/fedops-logger';
import { SeoLink } from '../../SeoLink/SeoLink';
import { SubCategory } from '../SubCategory/SubCategory';
import s from '../Categories.scss';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { biLogger } from '../../../utils/BILogger';
import { useSidebarStateContext } from '../../../contexts/SidebarStateContext';

export interface CategoryProps extends WithTranslations {
  categoryId?: string;
  dataHook: string;
  className: string;
  title: string;
  items?: CategoryModel[];
  isActive?: boolean;
  expanded?: boolean;
  categoriesStore: CategoriesStore;
  interactionsStore: InteractionsStore;
  routingStore: RoutingStore;
  setActiveRef?: (element: HTMLElement) => void;
}

export const CategoryCmp: React.FC<CategoryProps> = (props) => {
  const { closeSidebar } = useSidebarStateContext();

  const { t, setActiveRef, isActive, categoryId } = props;
  const categoryLink = props.routingStore.routes.categoryRoute.get(categoryId);
  const title = categoryId === 'all' ? t('sideBar.seeAllTemplates') : props.title;
  const hasSubCategories = props.items.length > 0;

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (hasSubCategories) {
      const checked = e.target.checked;
      props.categoriesStore.toggleCategory(props.categoryId, checked);
    }
  };

  const handleToggle = (e): void => {
    if (!e.key || ['Enter', ' '].includes(e.key)) {
      e.preventDefault();
      if (hasSubCategories) {
        props.categoriesStore.toggleCategory(props.categoryId);
      }
    }
  };

  const handleToggleMouseDown = (e): void => {
    e.preventDefault();
  };

  const handleArrowClick = (e: React.MouseEvent<SVGElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const toAllLink = props.routingStore.routes.categoryRoute.get('all');
    props.routingStore.history.push(toAllLink, {
      shouldNotCloseSidebar: true,
    });
  };

  const regulateSidebar = (): void => {
    const prevCategorySlug = props.routingStore.categorySlug ?? 'all';
    const nextCategorySlug = props.categoryId;
    const isAlreadyOnThisCategory = prevCategorySlug === nextCategorySlug;

    if (isAlreadyOnThisCategory) {
      closeSidebar();
    }
  };

  const handleParentLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, to: string): void => {
    regulateSidebar();

    biLogger.logCategorySelect({ category: props.categoryId });

    props.interactionsStore.initInteraction('category_switch');
    startCategoryInteraction();

    if (hasSubCategories) {
      e.preventDefault();
      props.routingStore.history.push(to, { shouldNotCloseSidebar: true });
    }
  };

  const handleBackToParentCategoryClick = (): void => {
    regulateSidebar();

    biLogger.logCategorySelect({ category: props.categoryId });
  };

  return (
    <li className={props.className} data-hook={props.dataHook} data-bi-element="category" data-bi-element-value={title}>
      <input
        className={s.checkbox}
        data-hook="expanded"
        type="checkbox"
        checked={hasSubCategories ? props.expanded : false}
        disabled={!hasSubCategories}
        aria-disabled={!hasSubCategories}
        id={`toggle-category-${categoryId}`}
        onChange={handleCheckChange}
      />
      <label
        role="button"
        data-hook="toggle"
        className={cs(s.label, { [s.noLabel]: !hasSubCategories })}
        tabIndex={0}
        aria-disabled={!hasSubCategories}
        aria-expanded={props.expanded}
        aria-label={t('sideBar.toggleCategory', { category: title })}
        onClick={handleToggle}
        onMouseDown={handleToggleMouseDown}
        htmlFor={`toggle-category-${categoryId}`}
      >
        <Expander fill="currentColor" width="1em" height="1em" />
      </label>
      <SeoLink
        className={cs(s.link, {
          [s.noIcon]: !hasSubCategories,
          [s.active]: isActive,
        })}
        data-hook="link"
        to={categoryLink}
        onClick={(e) => {
          handleParentLinkClick(e, categoryLink);
        }}
        aria-current={props.isActive}
        innerRef={props.isActive ? setActiveRef : undefined}
      >
        <span className={s.linkText} data-hook="link-title">
          {title}
        </span>
        {!hasSubCategories && (
          <BackArrow
            data-hook="backArrow"
            fill="currentColor"
            width="1em"
            height="1em"
            onClick={(e) => handleArrowClick(e)}
          />
        )}
      </SeoLink>
      {hasSubCategories && (
        <ul className={s.subItems}>
          <li className={s.mobileCategoryLabel}>
            <span className={s.mobileLinkText} data-hook="subMenuCategoryTitle">
              {title}
            </span>
          </li>
          <li className={s.mobileCategoryLink}>
            <SeoLink
              className={cs({ [s.active]: isActive })}
              data-hook="subMenuCategoryLink"
              to={categoryLink}
              aria-current={props.isActive}
              innerRef={props.isActive ? setActiveRef : undefined}
              onClick={handleBackToParentCategoryClick}
            >
              <span className={s.mobileLinkText}>{t('sideBar.mobile.mainCategory.all')}</span>
            </SeoLink>
          </li>
          {props.items.map((subItem) => {
            const isActive = subItem === props.categoriesStore.activeSubCategory;
            const subCategoryLink = props.routingStore.routes.subCategoryRoute.get(categoryId, subItem.id);
            return (
              <SubCategory
                key={subItem.id}
                dataHook="subCategory"
                className={s.listSubItem}
                isActive={isActive}
                setRef={isActive && setActiveRef}
                title={subItem.title}
                to={subCategoryLink}
                onClick={() => biLogger.logCategorySelect({ category: categoryId, subCategory: subItem.id })}
              />
            );
          })}
        </ul>
      )}
    </li>
  );
};

export const Category = withTranslations(
  injectStoresV1('categoriesStore', 'interactionsStore', 'routingStore')(CategoryCmp),
);
