import React, { useMemo } from 'react';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { sanitizeSeoSectionHtml } from '../../../utils/textUtils';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { ConfigStore } from '../../stores/ConfigStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import s from './SeoSection.scss';

export interface SeoSectionProps {
  dataHook?: string;
  categoriesStore: CategoriesStore;
  experimentsStore: ExperimentsStore;
  configStore: ConfigStore;
  templatesStore: TemplatesStore;
}

const SeoSectionCmp: React.FC<SeoSectionProps> = (props) => {
  const {
    dataHook,
    categoriesStore: { activeCategory, activeSubCategory },
    configStore: {
      config: { identity: { loggedIn } = {} },
    },
    templatesStore: { page },
  } = props;

  const category = activeSubCategory || activeCategory;

  const seoSection = category?.seoSection ?? '';
  const isFirstPage = page === 0;

  const cleanSeoSection = useMemo(() => {
    return sanitizeSeoSectionHtml(seoSection);
  }, [seoSection]);

  if (!cleanSeoSection || loggedIn || !isFirstPage) {
    return null;
  }

  return <div data-hook={dataHook} className={s.wrapper} dangerouslySetInnerHTML={{ __html: `${cleanSeoSection}` }} />;
};

export const SeoSection = injectStoresV1(
  'categoriesStore',
  'experimentsStore',
  'configStore',
  'templatesStore',
)(SeoSectionCmp);
