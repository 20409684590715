import { action, observable, computed, makeObservable } from 'mobx';
import { CategoryModel } from '../model/CategoryModel';
import { i18n } from '../../i18n/i18n';
import { Category } from '../../web-api/domain/category';
import { ErrorReporter } from '../../errorHandling/ErrorReporter';
import { TemplatesStore } from './TemplatesStore';

export class CategoriesStore {
  private fetchedCategories: CategoryModel[];
  private fetchedIntentCategories: CategoryModel[];
  private expandedIds: string[] = [];
  private collapsedIds: string[] = [];

  constructor(
    private templatesStore: TemplatesStore,
    categories: Category[],
    private i18n: i18n,
    private errorReporter: ErrorReporter,
    intentCategories: Category[],
  ) {
    makeObservable<CategoriesStore, 'fetchedIntentCategories' | 'fetchedCategories' | 'expandedIds' | 'collapsedIds'>(
      this,
      {
        fetchedCategories: observable,
        fetchedIntentCategories: observable,
        expandedIds: observable,
        collapsedIds: observable,
        filters: computed,
        categories: computed,
        intentCategories: computed,
        activeSubCategory: computed,
        activeCategory: computed,
        activeMicroCategory: computed,
        expandedCategories: computed,
        toggleCategory: action,
      },
    );

    this.fetchedCategories = CategoriesStore.fromDTOs(categories);
    this.fetchedIntentCategories = CategoriesStore.fromDTOs(intentCategories);
  }

  get filters(): CategoryModel[] {
    return this.fetchedCategories
      .filter((category) => category.type === 'main')
      .filter((category) => category.id !== 'all');
  }

  get intentCategories(): CategoryModel[] {
    return this.fetchedIntentCategories;
  }

  get categories(): CategoryModel[] {
    const pureBusinessCategories = this.fetchedCategories.filter(
      (category) => category.type === 'business' && category.id !== 'all',
    );

    const categoryAll = this.fetchedCategories.find((category) => category.id === 'all');
    if (categoryAll) {
      return [categoryAll, ...pureBusinessCategories];
    }

    return pureBusinessCategories;
  }

  get activeMicroCategory(): CategoryModel | undefined {
    const { appPage } = this.templatesStore.loaded;
    if (appPage === 'microCategory') {
      return this.getIntentCategoryBySlug(this.templatesStore.loaded.category);
    }

    return undefined;
  }

  get activeSubCategory(): CategoryModel | undefined {
    const { appPage } = this.templatesStore.loaded;
    if (appPage === 'subCategory') {
      return this.getCategoryBySlug(this.templatesStore.loaded.category, this.templatesStore.loaded.subCategory);
    }

    return undefined;
  }

  get activeCategory(): CategoryModel | undefined {
    const { loaded } = this.templatesStore;

    if (loaded.appPage === 'category' || loaded.appPage === 'subCategory') {
      return this.getCategoryBySlug(loaded.category);
    }

    if (loaded.appPage === 'home') {
      return this.getCategoryBySlug('all');
    }

    return undefined;
  }

  get expandedCategories(): CategoryModel[] {
    const states = {
      ...('category' in this.templatesStore.loaded && { [this.templatesStore.loaded.category]: true }),
      ...(this.expandedIds.length &&
        Object.assign.apply(
          null,
          this.expandedIds.map((id) => ({ [id]: true })),
        )),
      ...(this.collapsedIds.length &&
        Object.assign.apply(
          null,
          this.collapsedIds.map((id) => ({ [id]: false })),
        )),
    };
    const ids = Object.keys(states).filter((id) => states[id]);

    return ids.map((id) => this.getCategoryBySlug(id));
  }

  toggleCategory(categoryId: string, expand?: boolean) {
    const isExpanded =
      expand !== undefined
        ? !expand
        : this.collapsedIds.includes(categoryId)
        ? false
        : this.expandedIds.includes(categoryId) ||
          ('category' in this.templatesStore.loaded && this.templatesStore.loaded.category === categoryId);
    if (isExpanded) {
      this.expandedIds = this.expandedIds.filter((id) => id !== categoryId);
      this.collapsedIds.push(categoryId);
    } else {
      this.collapsedIds = this.collapsedIds.filter((id) => id !== categoryId);
      this.expandedIds.push(categoryId);
    }
  }

  private getIntentCategoryBySlug(categorySlug: string): CategoryModel {
    return this.fetchedIntentCategories.flatMap((i) => i.items).find(({ id }) => id === categorySlug);
  }

  public getCategoryBySlug(categorySlug: string, subCategorySlug?: string): CategoryModel {
    const category = this.fetchedCategories.find(({ id }) => id === categorySlug);
    if (category && subCategorySlug) {
      return category.items.find(({ id }) => id === subCategorySlug);
    }
    return category;
  }

  private static fromDTOs(categories: Category[]): CategoryModel[] {
    return categories.map(CategoryModel.fromDTO);
  }

  // @todo: it doesn't actually return Category[], need to fix it
  private toDTOs(models: CategoryModel[]): Category[] {
    return models.map((model) => CategoryModel.toDTO(model));
  }

  public serialize() {
    return {
      categories: this.toDTOs(this.filters.concat(this.categories)),
      intentCategories: this.toDTOs(this.intentCategories),
    };
  }
}
