import React, { ChangeEvent, FormEvent } from 'react';
import { autorun, when } from 'mobx';
import { RoutingStore } from '../../stores/RoutingStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { InteractionsStore } from '../../stores/InteractionsStore';
import SearchIcon from '../../../Icons/Search.svg';
import { startSearchInteraction } from '../../utils/fedops-logger';
import { MetaStore } from '../../stores/MetaStore';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import s from './Search.scss';

export interface SearchProps extends WithTranslations {
  dataHook?: string;
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
  interactionsStore: InteractionsStore;
  metaStore: MetaStore;
  onSearch?: () => void;
  experimentsStore: ExperimentsStore;
}

export interface SearchState {
  query: string;
}

class SearchCmp extends React.Component<SearchProps, SearchState> {
  private unsubscribeNavigation;
  private input: HTMLInputElement;

  constructor(props: SearchProps) {
    super(props);
    this.state = {
      query: props.routingStore.appPage === 'searchResults' ? props.routingStore.searchQuery : '',
    };
  }

  public componentDidMount(): void {
    this.unsubscribeNavigation = autorun(() => {
      const resetConditions = [this.props.routingStore.categorySlug, this.props.routingStore.subCategorySlug];

      if (resetConditions.some(Boolean)) {
        this.setState({
          query: '',
        });
      }
    });
  }

  public componentWillUnmount(): void {
    this.unsubscribeNavigation && this.unsubscribeNavigation();
  }

  private handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      query: e.target.value,
    });
  };

  private handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.submit();
  };

  private handleReset = (): void => {
    this.setState({
      query: '',
    });
    this.input?.focus?.();
  };

  private submit = (): void => {
    const query = this.state.query.trim();
    startSearchInteraction();
    this.props.interactionsStore.initInteraction('search');
    this.props.routingStore.search(query);
    when(
      () => this.props.templatesStore.loaded.criteria === query,
      () => {
        if (!this.props.templatesStore.isEmptySearch) {
          this.input?.blur();
        }
        this.props.onSearch?.();
      },
    );
  };

  public render() {
    const { t } = this.props;
    const isNoResults = this.props.templatesStore.isEmptySearch;
    const searchQuery = this.props.templatesStore.loaded.criteria;

    return (
      <form
        role="search"
        onSubmit={this.handleSubmit}
        action={this.props.routingStore.routes.base.get()}
        method="GET"
        className={s.search}
        data-hook={this.props.dataHook}
      >
        <label htmlFor="search-criteria" className={s.searchLabel}>
          <SearchIcon fill="currentColor" width="1em" height="1em" className={s.searchIcon} />
        </label>
        <input
          ref={(input) => (this.input = input)}
          id="search-criteria"
          name="criteria"
          className={s.input}
          autoComplete="off"
          onChange={this.handleInput}
          aria-label={t('search.label')}
          data-hook="searchInput"
          data-bi-element="search"
          data-bi-element-value="NULL"
          type="search"
          value={this.state.query}
          placeholder={t('search.placeholder')}
        />
        <hr className={s.horizontalLine} />
        <input type="hidden" name="page" value="1" />
        <input type="reset" className={s.reset} onClick={this.handleReset} />
        {isNoResults && (
          <div className={s.emptyMessage} data-hook="emptyMessage">
            <p className={s.emptyMessageText}>{t('templates.title.emptyZeroSearchResults', { query: searchQuery })}</p>
          </div>
        )}
      </form>
    );
  }
}

export const Search = withTranslations(
  injectStoresV1('routingStore', 'templatesStore', 'interactionsStore', 'experimentsStore', 'metaStore')(SearchCmp),
);
