import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import webBiLogger from '@wix/web-bi-logger';

import {
  templatesGenericPageView,
  templatesViewTemplate,
  templatesSearch,
  templatesTemplateAboveTheFoldImpression,
  templatesPageMainCategoryMenu,
  clickSaveMobileTemplatesPage,
  thankYouPageActionMobileTemplatesPage,
  clickInstallAppInMobileTemplatePage,
  templatesPageWebVitalsMetrics,
} from '@wix/bi-logger-spettatore-bi/v2';
import {
  templatesStartedLoadingThePageParams,
  templatesFinishedLoadingThePageParams,
  templatesPagePageFullyRenderedParams,
  templatesGenericPageViewParams,
  templatesViewTemplateParams,
  templatesPageMainCategoryMenuParams,
  templatesSearchParams,
  clickSaveMobileTemplatesPageParams,
} from '@wix/bi-logger-spettatore-bi/v2/types';
import { templatesPageMicroCategorySrc2Evid856 } from '@wix/bi-logger-top-funnel-data/v2';

import { LogParams, ReportProps } from '@wix/web-bi-logger/dist/src/types';
import { InteractionType } from '../stores/InteractionsStore';
import { Template } from '../../web-api/domain/template';
import { mapTemplatesToJson } from '../../utils/templatesToBiData';
import { BaseBILogger } from '../../BI/BaseBILogger';
import { WebVitalsMetric } from '../../BI/webVitals';
import { BI_MICRO_CATEGORIES_CLICK_SOURCE } from '../../consts';

const bi = webBiLogger.factory({ endpoint: 'spettatore_bi' }).updateDefaults({ src: 2 }).logger();

interface TemplatesSearchBI {
  criteria: string;
  currentPage: number;
  numberOfTemplatesInCurrentPage: number;
  source: string;
  totalPages: number;
}

export type TemplatesStartedLoadingBI = templatesStartedLoadingThePageParams &
  Required<Pick<templatesStartedLoadingThePageParams, 'category' | 'page_index'>> & {
    interaction_type: InteractionType;
  };

export type TemplatesFinishedLoadingBI = templatesFinishedLoadingThePageParams &
  Required<Pick<templatesFinishedLoadingThePageParams, 'category' | 'page_index' | 'loadingTime'>> & {
    interaction_type: InteractionType;
    templates: Template[];
  };

export type TemplatesFinishedRenderingBI = templatesPagePageFullyRenderedParams &
  Required<Pick<templatesPagePageFullyRenderedParams, 'category' | 'loadingTime'>> & {
    interaction_type: InteractionType;
    templates: Template[];
    page_index: number;
  };

interface TemplateHoverBI {
  btn: boolean;
  category: string;
  index: number;
  openingbrowserurl: string;
  openingSiteID: string;
  siteType: number;
  template_id: string;
  hover_ms: number;
}

export type TemplateViewBIOrigin = 'title' | 'thumbnail' | 'button';

interface TemplateViewBI {
  btn: boolean;
  category: string;
  subCategory: string;
  currentPageNumber: number;
  index: number;
  openingBrowserUrl: string;
  openingSiteId: string;
  siteType: number;
  origin: TemplateViewBIOrigin;
  isSearchResult: boolean;
  total_search_results: number;
  search_guid?: string;
  score?: number;
}

export interface CategorySelectParams {
  category: string;
  subCategory?: string;
}

interface TemplatePaginationBI {
  category: string;
  page_clicked_index: number;
  pagination_type: string;
  page_index: number;
}

interface ClickOnElementsBI {
  clicked_element: string;
  clicked_element_identifier: string;
  gallerydocindex: string;
  category: string;
  page_index: number;
}

interface TemplateImpressionBI {
  category: string;
  siteType: number;
  template_id: string;
  page_index: number;
  galleryDocIndex: number;
}

const docsPerPage = 12;

const getWindowParams = () => {
  if (typeof window !== 'object') {
    return {};
  }

  return {
    window_width: window.innerWidth,
    window_height: window.innerHeight,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    avail_width: window.screen.availWidth,
    avail_height: window.screen.availHeight,
  };
};

const log = (props: LogParams, logger = bi): Promise<void> => {
  if (typeof window === 'object') {
    return logger.log(props).catch(() => Promise.resolve());
  }
  return Promise.resolve();
};

export const logTemplatesStartLoading = (props: TemplatesStartedLoadingBI): Promise<void> => {
  return log({
    evid: 22,
    book: '',
    galleryDocsPerPage: docsPerPage,
    platform: 'web',
    product: 'html',
    ...props,
  });
};

export const logTemplatesFinishLoading = (props: TemplatesFinishedLoadingBI) => {
  return log({
    evid: 23,
    book: '',
    galleryDocsPerPage: docsPerPage,
    platform: 'web',
    product: 'html',
    event_data: mapTemplatesToJson(props.templates),
    category: props.category,
    page_index: props.page_index,
    loadingTime: props.loadingTime,
    interaction_type: props.interaction_type,
  });
};

export const logTemplatesFullyRendered = (props: TemplatesFinishedRenderingBI) => {
  return log({
    evid: 51,
    book: '',
    gallerydocsperpage: docsPerPage,
    platform: 'web',
    product: 'html',
    event_data: mapTemplatesToJson(props.templates),
    category: props.category,
    page_index: props.page_index,
    loadingTime: props.loadingTime,
    interaction_type: props.interaction_type,
  });
};

export const logTemplateHover = (props: TemplateHoverBI) => {
  return log({
    evid: 12,
    book: '',
    galleryDocsPerPage: docsPerPage,
    product: 'html',
    ...props,
    ...getWindowParams(),
  });
};

export const logTemplateInfoShown = () => {
  return log({
    evid: 24,
    galleryDocsPerPage: docsPerPage,
  });
};

export const logTemplateInfoHidden = ({ closed_from }: { closed_from: 'hover out' | 'x button' }) => {
  return log({
    evid: 25,
    closed_from,
    galleryDocsPerPage: docsPerPage,
  });
};

export const logClickOnElements = (props: ClickOnElementsBI) => {
  return log({
    evid: 48,
    book: '',
    product: 'html',
    gallerydocsperpage: docsPerPage,
    ...props,
  });
};

export const logPaginatingType = (props: TemplatePaginationBI) => {
  return log({
    evid: 54,
    ...props,
  });
};

class BILogger implements BaseBILogger {
  private logger = webBiLogger.factory().logger();
  private log(payload: ReportProps): Promise<any> {
    const cleanPayload: ReportProps = { ...payload, params: omitBy(payload.params, isNil) };
    return this.logger.report(cleanPayload);
  }

  public logTemplatesLoaded(params: templatesGenericPageViewParams) {
    const { category, sub_category, criteria, page_number, origin, ...optionalProps } = params;
    const { screen_height, screen_width, ...windowParams } = getWindowParams();

    const requiredFields: templatesGenericPageViewParams = {
      category,
      sub_category,
      criteria,
      page_number,
      origin,
      screen_height,
      screen_width,
    };

    const legacyFields: templatesGenericPageViewParams = {
      gallerydocsperpage: docsPerPage,
      ...optionalProps,
      ...windowParams,
    };

    return this.log(
      templatesGenericPageView({
        ...requiredFields,
        ...legacyFields,
      }),
    );
  }

  public logTemplateView(params: TemplateViewBI) {
    const {
      index,
      openingSiteId,
      isSearchResult,
      category,
      subCategory,
      currentPageNumber,
      openingBrowserUrl,
      score,
      ...restParams
    } = params;

    const requiredFields: templatesViewTemplateParams = {
      index,
      category,
      sub_category: subCategory,
      pageNumber: currentPageNumber,
      openingSiteID: openingSiteId,
      is_search_result: isSearchResult,
    };

    const legacyFields: templatesViewTemplateParams = {
      book: '',
      product: 'html',
      gallerydocsperpage: docsPerPage,
      openingSiteID: openingSiteId,
      openingBrowserUrl,
      score_vespa: String(score ?? ''),
      ...restParams,
      ...getWindowParams(),
    };

    return this.log(
      templatesViewTemplate({
        ...requiredFields,
        ...legacyFields,
      }),
    );
  }

  public logCategorySelect({ category, subCategory }: CategorySelectParams) {
    const payload: templatesPageMainCategoryMenuParams = {
      click_source: 'mobile_menu',
      category,
      sub_category: subCategory,
    };

    return this.log(templatesPageMainCategoryMenu(payload));
  }

  public logSearch(params: TemplatesSearchBI) {
    const { criteria, ...restParams } = params;

    const requiredFields: templatesSearchParams = {
      category: 'search',
      criteria,
    };

    const legacyFields: templatesSearchParams = {
      book: '',
      ...restParams,
    };

    return this.log(
      templatesSearch({
        ...requiredFields,
        ...legacyFields,
      }),
    );
  }

  public logTemplateInViewport(params: TemplateImpressionBI) {
    return this.log(
      templatesTemplateAboveTheFoldImpression({
        book: '',
        galleryDocsPerPage: docsPerPage,
        ...params,
        ...getWindowParams(),
      }),
    );
  }

  public logSaveTemplateClick(params: clickSaveMobileTemplatesPageParams) {
    return this.log(clickSaveMobileTemplatesPage(params));
  }

  public logActionOnThankYouPage(actionName: 'close' | 'back-to-gallery') {
    return this.log(
      thankYouPageActionMobileTemplatesPage({
        action_name: actionName,
      }),
    );
  }

  public logInstallAppOnThankYouPage() {
    return this.log(clickInstallAppInMobileTemplatePage({ entry_point: 'mobile_flow' }));
  }

  public logWebVitals(props: WebVitalsMetric) {
    const { name, id } = props;
    let { value, delta } = props;

    // CLS values low (0.01 etc.), so we multiplying them before rounding
    if (name === 'CLS') {
      value = value * 1000;
      delta = delta * 1000;
    }

    // BI event requires Int, not Float numbers
    value = Math.round(value);
    delta = Math.round(delta);

    return this.log(
      templatesPageWebVitalsMetrics({
        metric_name: name,
        metric_value: value,
        id,
        delta,
      }),
    );
  }

  // 2:856
  public logMicroCategoryClick = ({
    microCategory,
    selectedValue,
    category,
    subCategory,
    pageIndex,
  }: {
    pageIndex: number;
    microCategory: string;
    selectedValue: string;
    category: string;
    subCategory: string;
  }) => {
    this.log(
      templatesPageMicroCategorySrc2Evid856({
        micro_category: microCategory,
        click_source: BI_MICRO_CATEGORIES_CLICK_SOURCE,
        selected_value: selectedValue,
        action: 'open',
        category,
        sub_category: subCategory,
        page_index: pageIndex,
        book: '',
      }),
    );
  };
}

export const biLogger = new BILogger();
