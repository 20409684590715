import React from 'react';
import { LinkProps } from 'react-router-dom';
import { RoutingStore } from '../../stores/RoutingStore';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { Link } from './Link';

interface SeoLinkProps extends Omit<LinkProps, 'href' | 'to'> {
  routingStore: RoutingStore;
  to: string;
}

export const SeoLink = injectStoresV1('routingStore')(({ routingStore, ...props }: SeoLinkProps) => {
  return <Link {...props} href={props.to && routingStore && routingStore.routes.toAbsolute(props.to)} />;
});
