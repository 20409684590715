import { Category } from '../../web-api/domain/category';

export class CategoryModel {
  id: string;
  title: string;
  items: CategoryModel[];
  type: 'none' | 'main' | 'business' | 'sort' | 'exclusive' | 'intent';
  seoSection: string;

  constructor({
    id = '',
    title = '',
    items = [],
    type = 'none',
    seoSection = '',
  }: {
    id?: string;
    title?: string;
    items?: Category[];
    type?: 'none' | 'main' | 'business' | 'sort' | 'exclusive' | 'intent';
    seoSection: string;
  }) {
    this.id = id;
    this.title = title;
    this.items = items.map(CategoryModel.fromDTO);
    this.type = type;
    this.seoSection = seoSection;
  }

  static fromDTO(category: Category) {
    return new CategoryModel({
      id: category.name,
      title: category.displayName,
      items: category.subCategories,
      type: category.type,
      seoSection: category.seoSection,
    });
  }

  static toDTO(model: CategoryModel) {
    return {
      displayName: model.title,
      name: model.id,
      type: model.type,
      seoSection: model.seoSection,
      ...(model.items.length > 0 ? { subCategories: model.items.map(CategoryModel.toDTO) } : {}),
    };
  }
}
