import React, { UIEvent } from 'react';
import cs from 'classnames';
import { Observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { WithTranslation } from '@wix/wix-i18n-config';
import * as bi from '../../../utils/BILogger';
import { TemplateViewBIOrigin } from '../../../utils/BILogger';
import { CategoryModel } from '../../../model/CategoryModel';
import { TemplateModel } from '../../../model/TemplateModel';
import { isElementInView } from '../../../../utils/isElementInView';
import { ExperimentsStore } from '../../../../stores/ExperimentsStore';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { TemplatePreviewStore } from '../../../stores/TemplatePreviewStore';
import { RoutingStore } from '../../../stores/RoutingStore';
import { ConfigStore } from '../../../stores/ConfigStore';
import { FedopsLogger } from '../../../utils/fedops-logger';
import { injectStoresV1 } from '../../../stores/injectStoresV1';
import { withTranslations } from '../../../../utils/withTranslations';
import { AppearSensor } from '../../AppearSensor/AppearSensor';
import { CategoriesStore } from '../../../stores/CategoriesStore';
import { getViewerLink } from '../../../../utils/templateLinks';
import { EditorSessionIdContext } from '../../../../v2/contexts/EditorSessionIdContext';
import { TemplateThumbnail } from '../../TemplateThumbnail/TemplateThumbnail';
import s from './Template.scss';

interface Cancelable {
  cancel(): void;

  flush(): void;
}

export interface TemplateProps extends WithTranslation {
  dataHook?: string;
  className?: string;
  language: string;
  onInfoVisibilityChange?: Function;
  onHideInner?: Function;
  onLeave?: Function;
  onView?: Function;
  template: TemplateModel;
  category: CategoryModel;
  query: string;
  index: number;
  originUrl: string;
  templatePreviewStore: TemplatePreviewStore;
  experimentsStore: ExperimentsStore;
  templatesStore: TemplatesStore;
  routingStore: RoutingStore;
  fedopsLogger: FedopsLogger;
  impressionHandlerDelay?: number;
  configStore: ConfigStore;
  categoriesStore: CategoriesStore;
}

type ChangeInfoSource = 'x button';

interface DebouncedFunc extends Cancelable {
  (): void;
}

class TemplateCmp extends React.Component<TemplateProps> {
  static contextType = EditorSessionIdContext;
  private viewButtonRef: HTMLAnchorElement;
  public debouncedReportIfVisibleToUser: DebouncedFunc;

  constructor(props: TemplateProps) {
    super(props);

    const { impressionHandlerDelay = 500 } = this.props;
    this.debouncedReportIfVisibleToUser = debounce(this.reportIfVisibleToUser, impressionHandlerDelay);
  }

  public setViewButtonRef = (ref: HTMLAnchorElement) => (this.viewButtonRef = ref);

  public reportIfVisibleToUser = async () => {
    const { templatesStore, template } = this.props;

    if (templatesStore.wasTemplateInView(template.id)) {
      return;
    }

    if (isElementInView(this.viewButtonRef)) {
      templatesStore.addViewedTemplateId(template.id);
      await this.bi.impression();
    }
  };

  public componentDidMount() {
    void this.reportIfVisibleToUser();
    window.addEventListener('scroll', this.debouncedReportIfVisibleToUser);
    window.addEventListener('resize', this.debouncedReportIfVisibleToUser);
  }

  public componentWillUnmount() {
    this.debouncedReportIfVisibleToUser.cancel();
    window.removeEventListener('scroll', this.debouncedReportIfVisibleToUser);
    window.removeEventListener('resize', this.debouncedReportIfVisibleToUser);
  }

  private bi = {
    infoShown: () => {
      return bi.logTemplateInfoShown();
    },
    infoHidden: (closed_from: ChangeInfoSource) => {
      return bi.logTemplateInfoHidden({
        closed_from,
      });
    },
    view: (biProps: { origin: TemplateViewBIOrigin }) => {
      const viewLink = this.getViewLink();
      const { activeCategory, activeSubCategory } = this.props.categoriesStore;
      const isSearch = this.props.routingStore.isSearchResultsPage;

      return bi.biLogger.logTemplateView({
        index: this.props.index,
        category: activeCategory?.id ?? '',
        subCategory: activeSubCategory?.id ?? '',
        openingSiteId: this.props.template.siteId,
        isSearchResult: isSearch,
        currentPageNumber: this.props.routingStore.page || 1,
        btn: true,
        openingBrowserUrl: viewLink,
        score: this.props.template.score,
        siteType: 2,
        total_search_results: 0,
        search_guid: this.props.templatesStore.searchId,
        origin: biProps.origin,
      });
    },
    impression: () => {
      return bi.biLogger.logTemplateInViewport({
        category: this.props.templatesStore.biCategory,
        siteType: 2,
        template_id: this.props.template.siteId,
        page_index: this.props.templatesStore.page + 1,
        galleryDocIndex: this.props.index,
      });
    },
  };

  private isNewMobileFlow = () => this.props.experimentsStore.is('specs.funnel.TemplatesGalleryNewMobileFlow', 'true');

  private handleThumbnailMouseDown = (e: React.MouseEvent) => {
    if (this.isNewMobileFlow()) {
      this.handleView(e, { origin: (e.target as HTMLElement).getAttribute('data-bi-origin') as TemplateViewBIOrigin });
    } else {
      const { template, routingStore } = this.props;
      routingStore.goToScreen('details', template.id);

      e.preventDefault();
      void this.reportInfoChange(true);
    }
  };

  private handleHideInfo = (e: React.MouseEvent) => {
    const { routingStore, template } = this.props;
    if (this.isNewMobileFlow()) {
      routingStore.goToScreen('preview', template.id);
    } else {
      routingStore.goToTemplatesScreen();
    }
    e.preventDefault();
    void this.reportInfoChange(false);
  };

  private handleNoop = (e: UIEvent) => {
    e.preventDefault();
  };

  private handleViewButtonClick = (e: React.MouseEvent) => {
    this.handleView(e, { origin: (e.target as HTMLElement).getAttribute('data-bi-origin') as TemplateViewBIOrigin });
  };

  private async reportInfoChange(isShown: boolean): Promise<void> {
    if (isShown) {
      await this.bi.infoShown();
    } else {
      await this.bi.infoHidden('x button');
    }

    if (this.props.onInfoVisibilityChange) {
      this.props.onInfoVisibilityChange();
    }
  }

  private handleView = (event: React.MouseEvent, biProps: { origin: TemplateViewBIOrigin }) => {
    const { template, routingStore, fedopsLogger } = this.props;
    routingStore.goToScreen('preview', template.id);
    fedopsLogger.interactionStarted('mobile-preview');
    event.preventDefault();

    this.bi.view(biProps).then(() => {
      this.props.onView && this.props.onView();

      this.context.refreshEditorSessionId();
    });
  };

  private getViewLink() {
    const templateSlug = this.props.template.id;
    const language = this.props.language;
    const originUrl = this.props.originUrl;
    const editorSessionId = this.context.editorSessionId;

    return getViewerLink({ templateSlug, language, originUrl, editorSessionId });
  }

  private renderTemplateThumbnail(
    viewLink: string,
    template: TemplateModel,
    imgAlt: string,
    index: number,
    infoToggleId: string,
  ):
    | string
    | number
    | boolean
    | {}
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal {
    return this.isNewMobileFlow() ? (
      <a
        className={s.mobileThumbLabel}
        onClick={this.handleNoop}
        onMouseDown={this.handleThumbnailMouseDown}
        href={viewLink}
        target="_blank"
        data-bi-origin="mobile_thumbnail"
        data-bi-element="template_thumbnail_mobile"
        data-bi-element-value={template.siteId}
        data-hook="viewTemplateLink"
      >
        <TemplateThumbnail
          className={s.mobileThumb}
          dataHook="mobileThumb"
          ariaLabel={this.props.t('template.mobilePreviewImg', { template: template.title })}
          alt={imgAlt}
          lazyLoad={index > 1}
          isMobileImage={true}
          imagePath={template.mobileImg}
        />
      </a>
    ) : (
      <label
        className={s.mobileThumbLabel}
        htmlFor={infoToggleId}
        onClick={this.handleNoop}
        onMouseDown={this.handleThumbnailMouseDown}
        data-hook="thumbnail"
        data-bi-origin="mobile_thumbnail"
        data-bi-element="template_thumbnail_mobile"
        data-bi-element-value={template.siteId}
      >
        <TemplateThumbnail
          className={s.mobileThumb}
          dataHook="mobileThumb"
          ariaLabel={this.props.t('template.mobilePreviewImg', { template: template.title })}
          alt={imgAlt}
          lazyLoad={index > 1}
          isMobileImage={true}
          imagePath={template.mobileImg}
        />
      </label>
    );
  }

  public render() {
    const { t, routingStore } = this.props;
    const category = this.props.query || this.props.category?.title;
    const { template, index, dataHook } = this.props;
    const viewLink = this.getViewLink();
    const imgAlt = t('template.previewAlt', { category, template: template.title });
    const infoToggleId = `template-info-toggle-${template.id}`;
    const infoId = `template-info-${template.id}`;
    const isNewMobileFlow = this.isNewMobileFlow();
    const isTemplateExpanded = template.id === routingStore.templateSlug;
    const isTemplateDetailsShown = isNewMobileFlow
      ? routingStore.screen === 'details' && isTemplateExpanded
      : isTemplateExpanded;

    return (
      <AppearSensor>
        {({ isAppear }) => (
          <Observer>
            {() => (
              <li key={template.id} className={s.template} data-hook={dataHook} data-bi-gallery-doc-index={index}>
                <input
                  type="checkbox"
                  className={s.infoToggle}
                  readOnly
                  data-hook="infoToggle"
                  checked={isTemplateDetailsShown}
                  id={infoToggleId}
                />
                {this.renderTemplateThumbnail(viewLink, template, imgAlt, index, infoToggleId)}
                <span
                  className={s.title}
                  data-hook="template-title"
                  data-bi-origin="title"
                  data-bi-element="template_title"
                  data-bi-element-value={template.siteId}
                >
                  {template.title}
                </span>
                <label
                  tabIndex={0}
                  aria-hidden={true}
                  aria-controls={infoId}
                  onClick={this.handleNoop}
                  onMouseDown={this.handleHideInfo}
                  role="checkbox"
                  aria-checked={isTemplateDetailsShown}
                  aria-label={t(isTemplateDetailsShown ? 'hideInfo' : 'showInfo')}
                  className={s.infoCloseIcon}
                  htmlFor={infoToggleId}
                  data-hook="mobile-info-close"
                />
                <div
                  className={cs(s.templateDetails, { [s.newMobileFlow]: isNewMobileFlow })}
                  data-hook="templateInner"
                >
                  <span className={s.mobileTitle} data-hook="mobile-template-title">
                    {template.title}
                  </span>
                  {isAppear && (
                    <div className={s.previewMobileWrap}>
                      <TemplateThumbnail
                        className={s.previewMobileImg}
                        dataHook="mobilePreviewImg"
                        ariaLabel={t('template.previewImg', { template: template.title })}
                        alt={imgAlt}
                        lazyLoad={false}
                        isMobileImage={true}
                        imagePath={template.mobileImg}
                      />
                    </div>
                  )}
                  {isAppear && (
                    <div className={s.previewWrap}>
                      <TemplateThumbnail
                        className={s.previewImg}
                        dataHook="previewImg"
                        lazyLoad={false}
                        ariaLabel={t('template.previewImg', { template: template.title })}
                        alt={imgAlt}
                        imagePath={template.previewImg}
                      />
                    </div>
                  )}
                  <div
                    className={s.hoverState}
                    data-hook="hoverPanel"
                    data-bi-origin="thumbnail"
                    data-bi-element="template_thumbnail"
                    data-bi-element-value={template.siteId}
                  >
                    {!isNewMobileFlow && (
                      <a
                        onMouseDown={this.handleNoop}
                        role="button"
                        data-bi-origin="button"
                        onClick={this.handleViewButtonClick}
                        className={s.view}
                        href={viewLink}
                        target="_blank"
                        data-hook="view"
                        ref={this.setViewButtonRef}
                      >
                        {t('template.view')}
                      </a>
                    )}
                    <div aria-hidden={!isTemplateDetailsShown} className={s.infoText} data-hook="infoText">
                      <div
                        onMouseDown={this.handleNoop}
                        tabIndex={0}
                        id={infoId}
                        className={s.infoInner}
                        data-hook="infoInner"
                      >
                        <div className={s.infoSection}>
                          {template.info.goodFor && (
                            <>
                              <p className={s.infoSectionHeading}>{t('template.goodFor')}</p>
                              <p className={s.infoSectionContent}>{template.info.goodFor}</p>
                            </>
                          )}
                          {template.info.description && (
                            <>
                              <p className={s.infoSectionHeading}>{t('template.description')}</p>
                              <p className={s.infoSectionContent}>{template.info.description}</p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </Observer>
        )}
      </AppearSensor>
    );
  }
}

export const Template = withTranslations(
  injectStoresV1(
    'experimentsStore',
    'templatePreviewStore',
    'routingStore',
    'templatesStore',
    'configStore',
    'categoriesStore',
  )(TemplateCmp),
);
