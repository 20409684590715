import { action, computed, observable, reaction, makeObservable } from 'mobx';
import { HttpError } from '@wix/http-client';
import { TemplateModel } from '../model/TemplateModel';
import { ExperimentsStore } from '../../stores/ExperimentsStore';
import { AppRoutes } from '../routes/AppRoutes';
import { ApiRoutes } from '../routes/ApiRoutes';
import * as bi from '../utils/BILogger';
import { Template } from '../../web-api/domain/template';
import { ApiClient, ApiClientFetchParams, ApiClientSearchParams } from '../../apiClient/ApiClient';
import { InteractionsStore } from './InteractionsStore';
import { RoutingStore, AppPage } from './RoutingStore';

export const ITEMS_PER_PAGE = 12;

interface ServerData {
  total?: number;
  templates?: TemplateModel[];
  notFound?: boolean;
  emptyResults?: boolean;
  itemsTotal?: number;
  searchId?: string;
}

type FetchParams = {
  page: number;
  url: string;
  interactionKey?: string;
} & (
  | (ApiClientFetchParams & { appPage: Exclude<AppPage, 'searchResults'> })
  | (ApiClientSearchParams & { appPage: Extract<AppPage, 'searchResults'> })
);

interface LoadedData {
  response: ServerData;
  fetchParams?: FetchParams;
}

export interface TemplatesStoreInitialState {
  page: number;
  templates: Template[];
  total: number;
  path: string;
  category?: string;
  subCategory?: string;
  criteria?: string;
  appPage: AppPage;
  notFound: boolean;
  emptyResults?: boolean;
  itemsTotal?: number;
  pageLimit?: number;
  searchId?: string;
}

function getBICategory(params: FetchParams) {
  return params.appPage === 'searchResults'
    ? 'search'
    : [params.category, params.subCategory].filter(Boolean).join('--');
}

export class TemplatesStore {
  initialTemplates: TemplateModel[] = [];
  initialSuggestions: TemplateModel[] = [];
  viewedTemplateIds: TemplateModel['id'][] = [];
  previousUrl: string = '';
  public networkError: Error | string | HttpError | null = null;

  constructor(
    initialState: TemplatesStoreInitialState,
    private routingStore: RoutingStore,
    private routes: AppRoutes,
    private apiRoutes: ApiRoutes,
    private apiClient: ApiClient,
    private experimentsStore: ExperimentsStore,
    private interactionsStore: InteractionsStore,
    private bookName: string,
  ) {
    makeObservable<TemplatesStore, 'cached' | 'fetchParams' | 'isFetchRequired' | 'updateCached' | 'setNetworkError'>(
      this,
      {
        initialTemplates: observable,
        initialSuggestions: observable,
        viewedTemplateIds: observable,
        previousUrl: observable,
        networkError: observable,
        templates: computed,
        cached: observable,
        page: computed,
        isEmptySearch: computed,
        totalPages: computed,
        itemsTotal: computed,
        notFound: computed,
        loaded: computed,
        fetchParams: computed,
        expandedTemplate: computed,
        hasExpandedTemplate: computed,
        isFetchRequired: computed,
        resetViewedTemplateIds: action,
        addViewedTemplateId: action,
        updateCached: action,
        setNetworkError: action,
        biCategory: computed,
        searchId: computed,
        redirectTarget: computed,
      },
    );

    const templates = initialState.templates.map(TemplateModel.fromDTO);

    const interaction = this.interactionsStore.initInteraction(
      'landing',
      typeof window !== 'undefined' && window.__START_TIME__,
    );

    const fetchParams =
      initialState.appPage === 'searchResults'
        ? {
            criteria: initialState.criteria,
            appPage: initialState.appPage,
            limit: ITEMS_PER_PAGE,
            offset: ITEMS_PER_PAGE * (initialState.page - 1),
            templatePageSessionId: '',
            biData: {
              page: initialState.page,
            },
            url: initialState.path,
            page: initialState.page,
            interactionKey: interaction.key,
          }
        : {
            url: initialState.path,
            page: initialState.page,
            interactionKey: interaction.key,
            appPage: initialState.appPage,
            category: initialState.category,
            subCategory: initialState.subCategory,
          };

    const data: LoadedData = {
      fetchParams,
      response: {
        templates,
        total: initialState.total,
        emptyResults: initialState.emptyResults,
        notFound: initialState.notFound,
        itemsTotal: initialState.itemsTotal,
        searchId: initialState.searchId,
      },
    };

    this.updateCached(data);

    this.interactionsStore.reportLoadingEnd(data.fetchParams.interactionKey, {
      category: getBICategory(data.fetchParams),
      page_index: data.fetchParams.page,
      templates: data.response.templates,
    });

    this.listenForUrlChanges();
  }

  get templates(): TemplateModel[] {
    return (this.cached.response.templates.length && this.cached.response.templates) || this.initialTemplates;
  }

  private cached: LoadedData = {
    response: {
      templates: [],
    },
  };

  get page(): number {
    return Math.max(this.cached.fetchParams.page - 1, 0);
  }

  get isEmptySearch(): boolean {
    return this.cached.response.emptyResults === true;
  }

  get totalPages(): number | undefined {
    const totalPages = Math.ceil(this.cached.response.total);
    return !isNaN(totalPages) ? totalPages : undefined;
  }

  get itemsTotal(): number {
    return this.cached.response.itemsTotal;
  }

  get notFound(): boolean {
    return this.cached.response.notFound;
  }

  get loaded(): FetchParams {
    return this.cached.fetchParams;
  }

  private get fetchParams(): FetchParams {
    const page = this.routingStore.page || 1;
    const appPage = this.routingStore.appPage;
    const interactionKey = this.interactionsStore.getLastInteractionKey();

    if (this.routingStore.getRedirectTarget()) {
      return null;
    }

    if (appPage === 'searchResults') {
      const criteria = this.routingStore.searchQuery;
      const url = this.apiRoutes.templates({
        criteria: this.routingStore.searchQuery,
        page,
      });

      return {
        url,
        page,
        appPage,
        criteria,
        interactionKey,
        biData: {
          page,
        },
        limit: ITEMS_PER_PAGE,
        offset: ITEMS_PER_PAGE * (page - 1),
        templatePageSessionId: '',
      };
    }

    if (appPage === 'microCategory') {
      const category = this.routingStore.microCategorySlug;

      return {
        page,
        url: this.apiRoutes.templates({ category, page }),
        appPage,
        interactionKey,
        bookName: this.bookName,
        category,
      };
    }

    const category = appPage === 'home' ? 'all' : this.routingStore.categorySlug;
    const subCategory = this.routingStore.subCategorySlug;
    const url = this.apiRoutes.templates({
      category,
      subCategory,
      page,
    });

    return {
      page,
      url,
      appPage,
      interactionKey,
      bookName: this.bookName,
      category,
      subCategory,
    };
  }

  public get hasExpandedTemplate(): boolean {
    return !!this.expandedTemplate;
  }

  public get expandedTemplate(): TemplateModel | undefined {
    const { templates } = this.cached.response;
    const { templateSlug } = this.routingStore;
    return templates.find((template) => template.id === templateSlug);
  }

  public relativeLinkProvider = (pageZeroBased: number): string => {
    const page = pageZeroBased > 0 && pageZeroBased + 1;
    switch (this.routingStore.appPage) {
      case 'microCategory':
        return this.routes.microCategoryRoute.get(this.routingStore.microCategorySlug, page);
      case 'searchResults':
        return this.routes.searchRoute.get(this.routingStore.searchQuery, page);
      case 'category':
        return this.routes.categoryRoute.get(this.routingStore.categorySlug, page);
      case 'home':
        return this.routes.categoryRoute.get('all', page);
      case 'subCategory':
        return this.routes.subCategoryRoute.get(
          this.routingStore.categorySlug,
          this.routingStore.subCategorySlug,
          page,
        );
      default:
        return this.routes.base.get();
    }
  };

  private get isFetchRequired() {
    return this.fetchParams !== null && this.fetchParams.url !== this.cached.fetchParams.url;
  }

  private listenForUrlChanges() {
    reaction(
      () => this.isFetchRequired,
      () => this.fetch(),
    );
  }

  async init() {
    this.updatePreviousUrl();
    await this.fetch();
  }

  private updatePreviousUrl() {
    this.previousUrl = (typeof window !== 'undefined' && window.location.href) || '';
  }

  public wasTemplateInView(id: TemplateModel['id']): boolean {
    return this.viewedTemplateIds.includes(id);
  }

  public resetViewedTemplateIds() {
    this.viewedTemplateIds = [];
  }

  public addViewedTemplateId(id: TemplateModel['id']) {
    this.viewedTemplateIds.push(id);
  }

  private async fetch() {
    if (!this.isFetchRequired) {
      return;
    }

    this.resetViewedTemplateIds();
    this.setNetworkError(null);
    let responseData: ServerData;
    try {
      void this.interactionsStore.reportLoadingStart(this.fetchParams.interactionKey, {
        category: getBICategory(this.fetchParams),
        page_index: this.fetchParams.page,
      });

      const data =
        this.fetchParams.appPage === 'searchResults'
          ? await this.apiClient.search(this.fetchParams, this.previousUrl)
          : await this.apiClient.fetchTemplates(this.fetchParams, this.previousUrl);

      const itemsTotal = data.itemsTotal;
      const total = data.pagination ? data.pagination.total : 0;
      const emptyResults =
        'selectedCategory' in data &&
        data.selectedCategory === 'most-popular' &&
        this.fetchParams.appPage === 'searchResults';

      const items = data.items ?? [];

      responseData = {
        total,
        searchId: 'searchId' in data ? data.searchId : undefined,
        templates: items.map(TemplateModel.fromDTO),
        notFound: (total > 0 && this.fetchParams.page > total) || (total === 0 && this.fetchParams.page > 1),
        emptyResults,
        itemsTotal,
      };
    } catch (error) {
      this.setNetworkError(error);
      const httpError = error as HttpError;
      if (httpError.isWixHttpError && httpError.response?.status !== 404) {
        throw httpError;
      }

      responseData = {
        total: 0,
        notFound: true,
        templates: [],
        itemsTotal: 0,
      };
    }

    const cacheData = { response: responseData, fetchParams: this.fetchParams };
    void this.reportResponse(cacheData);
    this.updateCached(cacheData);
    this.updatePreviousUrl();
  }

  private reportResponse(data: LoadedData): Promise<LoadedData> {
    return Promise.all([
      'criteria' in data.fetchParams
        ? bi.biLogger.logSearch({
            criteria: data.fetchParams.criteria,
            currentPage: data.fetchParams.page,
            numberOfTemplatesInCurrentPage: data.response.templates.length,
            source: 'search-box',
            totalPages: data.response.total,
          })
        : Promise.resolve(),
      this.interactionsStore.reportLoadingEnd(data.fetchParams.interactionKey, {
        category: getBICategory(data.fetchParams),
        page_index: data.fetchParams.page,
        templates: data.response.templates,
      }),
    ]).then(() => data);
  }

  public reportPageRendered() {
    this.interactionsStore.reportPageRendered(this.cached.fetchParams.interactionKey, {
      category: getBICategory(this.cached.fetchParams),
      page_index: this.cached.fetchParams.page,
      templates: this.cached.response.templates,
    });
  }

  private updateCached(cached: LoadedData) {
    this.cached = cached;
  }

  private setNetworkError(error: Error) {
    this.networkError = error;
  }

  public serialize(): TemplatesStoreInitialState {
    const state = {
      page: this.fetchParams.page,
      templates: this.templates.map(TemplateModel.toDTO),
      total: this.totalPages,
      path: this.fetchParams.url,
      notFound: this.notFound,
      appPage: this.loaded.appPage,
      emptyResults: this.isEmptySearch,
      itemsTotal: this.itemsTotal,
      searchId: this.searchId,
    };

    return this.loaded.appPage === 'searchResults'
      ? {
          ...state,
          criteria: this.loaded.criteria,
        }
      : {
          ...state,
          category: this.loaded.category,
          subCategory: this.loaded.subCategory,
        };
  }

  public get biCategory(): string {
    return getBICategory(this.loaded);
  }

  public get templateKeyPrefix(): string {
    const criteriaOrSlugs = 'criteria' in this.loaded ? this.loaded.criteria : getBICategory(this.loaded);
    const page = this.loaded.page;
    return `${criteriaOrSlugs}-${page}`;
  }

  get searchId(): string | undefined {
    return this.cached.response.searchId;
  }

  get redirectTarget(): string | null {
    return this.routingStore.getRedirectTarget(this.totalPages);
  }
}
