import { getImageExtensionFromType, ImageExtension, ImageMime } from '../../../utils/imageTypes';

interface GenerateSrcSetProps {
  isMobileImage: boolean;
  imageUrl: string;
  type: ImageMime;
}

export interface SrcSetProps {
  key: string;
  media: string;
  srcSet: string;
  type: ImageMime;
}

interface ImagePreloadLinkProps {
  imagesrcset: string;
  href: string;
  media: string;
}

const DESKTOP_PREVIEW_WIDTHS = [50, 100, 200, 300, 400, 500, 616, 650];
const MOBILE_PREVIEW_WIDTHS = [20, 50, 100, 150, 162, 200];
const DESKTOP_RATIO = 616 / 357;
const MOBILE_RATIO = 162 / 287;

export function generateImgUrl(imagesMediaPlatformDomain: string, imagePath: string) {
  return `//${imagesMediaPlatformDomain}${imagePath}`;
}

export function generateSrcSet({ imageUrl, type, isMobileImage }: GenerateSrcSetProps): SrcSetProps[] {
  const screenWidthRatio = isMobileImage ? 2.5 : 1;
  const ratio = isMobileImage ? MOBILE_RATIO : DESKTOP_RATIO;
  const widths = isMobileImage ? MOBILE_PREVIEW_WIDTHS : DESKTOP_PREVIEW_WIDTHS;
  const sizes = widths.map((width) => ({ w: width, h: Math.ceil(width / ratio) }));
  const extension = getImageExtensionFromType(type);
  return sizes.map(({ w, h }, i) => {
    const isLastSize = i === sizes.length - 1;
    return {
      key: `${w}-${type}`,
      ...(!isLastSize && { media: `(max-width: ${w * screenWidthRatio}px)` }),
      srcSet: `${imageUrl}/v1/fit/w_${w}%2Ch_${h}/file.${extension} ${w}w`,
      type,
    };
  });
}

export function generateImagePreloadLinks(imageUrl: string): ImagePreloadLinkProps[] {
  const screenWidthRatio = 2.5;
  const sizes = MOBILE_PREVIEW_WIDTHS.map((width) => ({ w: width, h: Math.ceil(width / MOBILE_RATIO) }));
  return sizes.map(({ w, h }, index) => {
    const currentImageUrl = `${imageUrl}/v1/fit/w_${w}%2Ch_${h}/file.${ImageExtension.WEBP}`;
    const minWidth = index > 0 ? sizes[index - 1].w * screenWidthRatio + 0.1 : 0;
    const maxWidth = w * screenWidthRatio;

    return {
      key: `${w}-${h}`,
      imagesrcset: `${currentImageUrl} ${w}w`,
      href: currentImageUrl,
      media: `${minWidth > 0 ? `(min-width: ${minWidth}px) and ` : ''}(max-width: ${maxWidth}px)`,
    };
  });
}
