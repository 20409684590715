import { getBasename, getOrigin, joinUrlParts, stripTrailingSlash } from '../../libs/urlUtils';

export type PublicRoute = {
  pattern: () => string;
  get: (...params: any[]) => string;
};

export type Screen = 'templates' | 'details' | 'preview' | 'thank-you';

export class AppRoutes {
  private readonly origin: string;
  private readonly basename: string;
  public primaryCategorySlug: string;

  constructor(baseURL: string) {
    this.origin = getOrigin(baseURL);
    this.basename = getBasename(baseURL);
    this.primaryCategorySlug = 'all';
  }

  public toAbsolute = (path?: string): string => joinUrlParts(this.origin, path || '');

  public isPrimaryCategoryRoute = (categorySlug: string, page?: number): boolean => {
    return categorySlug === this.primaryCategorySlug && (!page || page === 1);
  };

  public base: PublicRoute = {
    pattern: () => joinUrlParts('/', this.basename),
    get: () => joinUrlParts('/', this.basename),
  };

  public categoryRoute: PublicRoute = {
    pattern: () => joinUrlParts(this.base.pattern(), `html/:categorySlug/:page(\\d+)?`),
    get: (categorySlug: string, page?: number) => {
      return this.isPrimaryCategoryRoute(categorySlug, page)
        ? this.base.get()
        : joinUrlParts(this.base.get(), `html/${categorySlug}${page > 0 ? `/${page}` : ``}`);
    },
  };

  public microCategoryRoute: PublicRoute = {
    pattern: () => joinUrlParts(this.base.pattern(), `refine/:microCategorySlug/:page(\\d+)?`),
    get: (microCategorySlug: string, page?: number) =>
      joinUrlParts(this.base.get(), `refine/${microCategorySlug}${page > 0 ? `/${page}` : ``}`),
  };

  public subCategoryRoute: PublicRoute = {
    pattern: () => joinUrlParts(this.base.pattern(), `html/:categorySlug/:subCategorySlug/:page(\\d+)?`),
    get: (categorySlug: string, subCategorySlug: string, page?: number) =>
      `${this.categoryRoute.get(categorySlug)}/${subCategorySlug}${page > 0 ? `/${page}` : ``}`,
  };

  public searchRoute: PublicRoute = {
    pattern: () => this.base.pattern(),
    get: (query: string, page: number = 1): string => {
      page = page || 1;
      let base = stripTrailingSlash(this.base.get());
      if (base === '') {
        base = '/';
      }
      return base + `?criteria=${encodeURIComponent(query)}&page=${page}`;
    },
  };

  public categoryScreenRoute: PublicRoute = {
    pattern: () => this.categoryRoute.pattern(),
    get: (categorySlug: string, screen: Screen, templateSlug: string, page?: number): string =>
      `${this.categoryRoute.get(categorySlug, page)}?screen=${screen}&template-slug=${templateSlug}`,
  };

  public microCategoryScreenRoute: PublicRoute = {
    pattern: () => this.microCategoryRoute.pattern(),
    get: (microCategorySlug: string, screen: Screen, templateSlug: string, page?: number): string =>
      `${this.microCategoryRoute.get(microCategorySlug, page)}?screen=${screen}&template-slug=${templateSlug}`,
  };

  public subCategoryScreenRoute: PublicRoute = {
    pattern: () => this.subCategoryRoute.pattern(),
    get: (categorySlug: string, subCategory: string, screen: Screen, templateSlug: string, page?: number): string =>
      `${this.subCategoryRoute.get(categorySlug, subCategory, page)}?screen=${screen}&template-slug=${templateSlug}`,
  };

  public searchScreenRoute: PublicRoute = {
    pattern: () => this.searchRoute.pattern(),
    get: (criteria: string, screen: Screen, templateSlug: string, page?: number): string =>
      `${this.searchRoute.get(criteria, page)}&screen=${screen}&template-slug=${templateSlug}`,
  };

  public baseScreenRoute: PublicRoute = {
    pattern: () => this.base.pattern(),
    get: (screen: Screen, templateSlug: string): string => {
      let base = stripTrailingSlash(this.base.get());
      if (base === '') {
        base = '/';
      }
      return `${base}?screen=${screen}&template-slug=${templateSlug}`;
    },
  };
}
