import { configure } from 'mobx';
import { History } from 'history';
import { syncHistoryWithStore } from '../../stores/reactRouterStore/syncHistoryWithStore';
import { AppRoutes } from '../routes/AppRoutes';
import { i18n } from '../../i18n/i18n';
import { ExperimentsStore, ExperimentsStoreState } from '../../stores/ExperimentsStore';
import { ApiRoutes } from '../routes/ApiRoutes';
import {
  OverriddenCategoryNamesStore,
  OverriddenCategoryNamesStoreState,
} from '../../stores/OverriddenCategoryNamesStore';
import { Category } from '../../web-api/domain/category';
import { ErrorReporter } from '../../errorHandling/ErrorReporter';
import { ApiClient } from '../../apiClient/ApiClient';
import { CategoriesStore } from './CategoriesStore';
import { RoutingStore } from './RoutingStore';
import { MetaStore } from './MetaStore';
import { TemplatesStoreInitialState, TemplatesStore } from './TemplatesStore';
import { TemplatePreviewStore } from './TemplatePreviewStore';
import { InteractionsStore } from './InteractionsStore';
import { ConfigStore, ConfigStoreState } from './ConfigStore';
import { ThankYouStore } from './ThankYouStore';

configure({ enforceActions: 'observed' });

export interface InitialStoresState {
  bookName?: string;
  categories?: Category[];
  intentCategories?: Category[];
  route?: string;
  templates?: TemplatesStoreInitialState;
  experiments?: ExperimentsStoreState;
  config: ConfigStoreState;
  overriddenCategoryNames: OverriddenCategoryNamesStoreState;
}

export interface Stores {
  categoriesStore: CategoriesStore;
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
  metaStore: MetaStore;
  templatePreviewStore: TemplatePreviewStore;
  experimentsStore: ExperimentsStore;
  interactionsStore: InteractionsStore;
  configStore: ConfigStore;
  overriddenCategoryNamesStore: OverriddenCategoryNamesStore;
  thankYouStore: ThankYouStore;
}

export function getState(stores: Stores): InitialStoresState {
  return {
    templates: stores.templatesStore.serialize(),
    experiments: stores.experimentsStore.serialize(),
    config: stores.configStore.serialize(),
    overriddenCategoryNames: stores.overriddenCategoryNamesStore.serialize(),

    // categories + intentCategories
    ...stores.categoriesStore.serialize(),
  };
}

const defaultTemplatesStoreState: TemplatesStoreInitialState = {
  templates: [],
  page: 1,
  total: 0,
  notFound: false,
  path: '',
  appPage: 'home',
  itemsTotal: 0,
};

export async function initStores(parameters: {
  initialState: InitialStoresState;
  i18nInstance: i18n;
  history: History;
  routes: AppRoutes;
  apiRoutes: ApiRoutes;
  apiClient: ApiClient;
  errorReporter: ErrorReporter;
}): Promise<Stores> {
  const { initialState, i18nInstance, history, routes, apiRoutes, apiClient, errorReporter } = parameters;
  const routingStore = new RoutingStore(routes, errorReporter);
  history && syncHistoryWithStore(history, routingStore);
  const experimentsStore = new ExperimentsStore(initialState && initialState.experiments);
  const templatesInitialState = { ...defaultTemplatesStoreState, ...(initialState ? initialState.templates : {}) };
  const interactionsStore = new InteractionsStore();
  const templatesStore = new TemplatesStore(
    templatesInitialState,
    routingStore,
    routes,
    apiRoutes,
    apiClient,
    experimentsStore,
    interactionsStore,
    initialState?.bookName,
  );
  const categoriesStore = new CategoriesStore(
    templatesStore,
    initialState?.categories || [],
    i18nInstance,
    errorReporter,
    initialState?.intentCategories || [],
  );
  const templatePreviewStore = new TemplatePreviewStore(routingStore, templatesStore, apiRoutes);

  const overriddenCategoryNamesStore = new OverriddenCategoryNamesStore(
    i18nInstance,
    initialState.overriddenCategoryNames,
  );

  const metaStore = new MetaStore(
    routingStore,
    templatePreviewStore,
    templatesStore,
    categoriesStore,
    experimentsStore,
    overriddenCategoryNamesStore,
    i18nInstance,
  );
  const configStore = new ConfigStore(initialState.config);

  const stores = {
    categoriesStore,
    routingStore,
    templatesStore,
    metaStore,
    templatePreviewStore,
    experimentsStore,
    interactionsStore,
    configStore,
    overriddenCategoryNamesStore,
    thankYouStore: new ThankYouStore(templatesStore, routingStore, apiClient),
  };

  await stores.templatesStore.init();
  return stores;
}
