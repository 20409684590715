import React from 'react';
import { withTranslations, WithTranslations } from '../../../../utils/withTranslations';
import WixOwnerAppIcon from '../../../../Icons/WixOwnerAppIcon.svg';
import { biLogger } from '../../../utils/BILogger';
import { thankYouDataHooks } from '../ThankYou.dataHooks';
import s from './WixOwnerAppBanner.scss';

interface WixOwnerAppBannerProps extends WithTranslations {
  dataHook?: string;
}

const WixOwnerAppBannerCmp: React.FC<WixOwnerAppBannerProps> = ({ t }) => {
  return (
    <div className={s.banner}>
      <WixOwnerAppIcon className={s.appIcon} />
      <div className={s.description}>{t('thankYou.wixOwnerAppBanner.description')}</div>
      <a
        href="https://vgal2.app.link/"
        data-hook={thankYouDataHooks.wixOwnerInstallButton()}
        className={s.cta}
        onClick={() => {
          biLogger.logInstallAppOnThankYouPage();
        }}
      >
        {t('thankYou.wixOwnerAppBanner.cta')}
      </a>
    </div>
  );
};

export const WixOwnerAppBanner = withTranslations(WixOwnerAppBannerCmp);
