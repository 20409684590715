import React, { FC } from 'react';
import cs from 'classnames';
import { SeoLink } from '../SeoLink/SeoLink';

import s from '../../../common/styles/TagLink.scss';

type TagLinkProps = {
  title: string;
  isActive?: boolean;
  onClick?: VoidFunction;
  to: string;
};

export const TagLink: FC<TagLinkProps> = ({ onClick, isActive, title, to }) => (
  <SeoLink className={cs(s.tagLink, { [s.active]: isActive })} to={to} onClick={onClick}>
    {title}
  </SeoLink>
);
