import { computed, makeObservable } from 'mobx';
import { ExperimentsStore } from '../../stores/ExperimentsStore';
import { i18n } from '../../i18n/i18n';
import { OverriddenCategoryNamesStore } from '../../stores/OverriddenCategoryNamesStore';
import { CategoriesStore } from './CategoriesStore';
import { TemplatesStore } from './TemplatesStore';
import { RoutingStore } from './RoutingStore';
import { TemplatePreviewStore } from './TemplatePreviewStore';

export class MetaStore {
  constructor(
    private routingStore: RoutingStore,
    private templatePreviewStore: TemplatePreviewStore,
    private templatesStore: TemplatesStore,
    private categoriesStore: CategoriesStore,
    private experimentsStore: ExperimentsStore,
    private overriddenCategoryNamesStore: OverriddenCategoryNamesStore,
    private i18nInstance: i18n,
  ) {
    makeObservable<MetaStore>(this, {
      documentTitle: computed,
      language: computed,
      noindex: computed,
      noFollow: computed,
      description: computed,
    });
  }

  get documentTitle(): string {
    const { activeSubCategory, activeCategory, activeMicroCategory } = this.categoriesStore;
    const { criteria, page, appPage } = this.templatesStore.loaded;
    const category = this.overriddenCategoryNamesStore.getPageTitleCategoryName(activeCategory);
    const subCategory = this.overriddenCategoryNamesStore.getPageTitleCategoryName(activeSubCategory);
    const microCategory = this.overriddenCategoryNamesStore.getPageTitleCategoryName(activeMicroCategory);

    if (appPage === 'microCategory') {
      return this.i18nInstance.t('seo.title.category.v2', { category: microCategory, page });
    }

    if (appPage === 'home') {
      return this.i18nInstance.t('seo.title.home');
    }

    if (appPage === 'searchResults') {
      return this.i18nInstance.t('seo.title.searchResults', { query: criteria });
    }

    if (activeCategory?.id === 'all') {
      return this.i18nInstance.t('seo.title.category.allPage', { page });
    }

    if (appPage === 'category') {
      if (activeCategory?.id === 'design') {
        return this.i18nInstance.t('seo.title.category.v2.design', { page });
      }
      return this.i18nInstance.t('seo.title.category.v2', { category, page });
    }

    if (appPage === 'subCategory') {
      return this.i18nInstance.t('seo.title.subCategory.v2', { category, subCategory, page });
    }

    return '';
  }

  get language(): string {
    return this.i18nInstance.language;
  }

  get noindex(): boolean {
    const loaded = this.templatesStore.loaded;
    return loaded.appPage === 'searchResults' || this.routingStore.screen === 'thank-you';
  }

  get noFollow(): boolean {
    return this.routingStore.appPage === 'searchResults' || this.routingStore.screen === 'thank-you';
  }

  get description(): string {
    const { appPage } = this.templatesStore.loaded;
    const { activeSubCategory, activeCategory, activeMicroCategory } = this.categoriesStore;

    if (appPage === 'microCategory') {
      const categoryName = this.overriddenCategoryNamesStore.getPageTitleCategoryName(activeMicroCategory);

      return this.i18nInstance.t('seo.description.category.v2', { category: categoryName });
    }

    if (appPage === 'home') {
      return this.i18nInstance.t('seo.description.home');
    }

    const categoryName =
      appPage === 'category'
        ? this.overriddenCategoryNamesStore.getPageTitleCategoryName(activeCategory)
        : this.overriddenCategoryNamesStore.getPageTitleCategoryName(activeSubCategory);

    if (appPage === 'category' || appPage === 'subCategory') {
      return activeCategory?.id === 'all'
        ? this.i18nInstance.t('seo.description.category.all')
        : this.i18nInstance.t('seo.description.category.v2', { category: categoryName });
    }

    return '';
  }

  get hrefLangsEnabled(): boolean {
    const { appPage } = this.templatesStore.loaded;

    // micro cats only en right now
    return appPage !== 'microCategory';
  }
}
