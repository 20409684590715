import React from 'react';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import s from './ToggleIcon.scss';

export interface CollapseButtonProps extends WithTranslations {
  dataHook?: string;
  onToggle: React.EventHandler<React.MouseEvent<HTMLLabelElement>>;
}

const Arrow = () => (
  <div className={s.sbsArrow}>
    <hr className={s.sbsArrowHand} />
    <hr className={s.sbsArrowHand} />
  </div>
);

export const CollapseButton = withTranslations(({ dataHook, t, onToggle }: CollapseButtonProps) => (
  <label
    role="button"
    htmlFor="sb-switch"
    tabIndex={0}
    className={s.label}
    onClick={onToggle}
    onMouseDown={(e) => e.preventDefault()}
    data-hook={dataHook}
    aria-label={t('sideBar.hideSidebar')}
  >
    <Arrow />
  </label>
));
