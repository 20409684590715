import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { HttpClient } from '@wix/http-client';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { LocalStorageCapsule } from '@wix/data-capsule';
import { logPerformance } from '../BI/performance';
import { clientI18n } from '../i18n/client-i18n';
import { isWixDomain } from '../utils/isWixDomain';
import { initCookieAndTagManagers } from '../utils/initCookieAndTagManagers';
import { initWixRecorder } from '../utils/initWixRecorder';
import { ConsoleErrorReporter, SentryErrorReporter } from '../errorHandling/ErrorReporter';
import { RenderErrorsHandler } from '../v2/components/RenderErrorsHandler/RenderErrorsHandler';
import { BrowserApiClient } from '../apiClient/BrowserApiClient';
import { EditorSessionIdProvider } from '../v2/contexts/EditorSessionIdContext';
import { reportWebVitals } from '../BI/webVitals';
import { SavedTemplateProvider } from './contexts/SavedTemplateContext';
import { App } from './components/App/App';
import { AppRoutes } from './routes/AppRoutes';
import { initStores, InitialStoresState } from './stores/initStores';
import { getLogger } from './utils/fedops-logger';
import { ApiRoutes } from './routes/ApiRoutes';
import { SidebarStateProvider } from './contexts/SidebarStateContext';
import { biLogger } from './utils/BILogger';

const baseURL = window.__BASEURL__;
const initialI18n = window.__INITIAL_I18N__;
const initialState: InitialStoresState = window.__INITIAL_DATA__;
const editorSessionId = window.__EDITOR_SESSION_ID__;

(async () => {
  if (!isWixDomain(location.hostname)) {
    console.warn(`Application runs on wrong origin`);
    return;
  }

  const errorReporter = window.Sentry ? new SentryErrorReporter(window.Sentry) : new ConsoleErrorReporter();

  const routes = new AppRoutes(baseURL);
  const apiRoutes = new ApiRoutes(baseURL);
  const httpClient = new HttpClient({ baseURL });
  const apiClient = new BrowserApiClient(httpClient);
  const i18nInstance = clientI18n(initialI18n, errorReporter);
  const browserHistory = createBrowserHistory();
  const dataCapsule = LocalStorageCapsule({
    namespace: 'marketing-templates',
  });

  const stores = await initStores({
    initialState,
    i18nInstance,
    history: browserHistory,
    routes,
    apiRoutes,
    apiClient,
    errorReporter,
  });
  const { consentPolicy } = stores.configStore.config;
  const { language } = i18nInstance;

  initCookieAndTagManagers({ baseUrl: baseURL, language, consentPolicy }, errorReporter);
  initWixRecorder(initialState.experiments, errorReporter);

  const application = (
    <RenderErrorsHandler errorReporter={errorReporter}>
      <HelmetProvider>
        <Provider {...stores}>
          <Router history={browserHistory}>
            <I18nextProvider i18n={i18nInstance}>
              <EditorSessionIdProvider initialEditorSessionId={editorSessionId}>
                <SavedTemplateProvider dataCapsule={dataCapsule}>
                  <SidebarStateProvider>
                    <Route component={App} />
                  </SidebarStateProvider>
                </SavedTemplateProvider>
              </EditorSessionIdProvider>
            </I18nextProvider>
          </Router>
        </Provider>
      </HelmetProvider>
    </RenderErrorsHandler>
  );

  if (stores.experimentsStore.is('specs.funnel.TemplatesGalleryUseReactDomHydrate', 'true')) {
    ReactDOM.hydrate(application, document.getElementById('root'));
  } else {
    ReactDOM.render(application, document.getElementById('root'));
  }

  reportWebVitals(biLogger.logWebVitals.bind(biLogger));
  getLogger().appLoaded();
  void (await logPerformance(window.appReady));
})();
