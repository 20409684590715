import { create, FedopsLogger as Logger } from '@wix/fedops-logger';

export type Interaction = 'search' | 'pagination' | 'category-switch' | 'embed-chat' | 'mobile-preview';

export type FedopsLogger = Logger<Interaction>;

let activeInteraction: Interaction | '';

let logger: FedopsLogger;

export function getLogger() {
  logger = logger || create('marketing-templates', { reportBlackbox: true });
  return logger;
}

export function startSearchInteraction() {
  activeInteraction = 'search';
  getLogger().interactionStarted(activeInteraction);
}

export function startPaginationInteraction() {
  activeInteraction = 'pagination';
  getLogger().interactionStarted(activeInteraction);
}

export function startCategoryInteraction() {
  activeInteraction = 'category-switch';
  getLogger().interactionStarted(activeInteraction);
}

export function stopActiveInteraction() {
  if (activeInteraction) {
    getLogger().interactionEnded(activeInteraction);
    activeInteraction = '';
  }
}

export function getActiveInteraction() {
  return activeInteraction;
}
