import { action, computed, makeObservable } from 'mobx';
import { matchPath } from 'react-router-dom';
import urlParse from 'url-parse';
import { RouterStore } from '../../stores/reactRouterStore/RouterStore';
import { AppRoutes, PublicRoute, Screen } from '../routes/AppRoutes';
import { ErrorReporter } from '../../errorHandling/ErrorReporter';

export type AppPage = 'home' | 'category' | 'subCategory' | 'searchResults' | 'thankYou' | 'microCategory';

export class RoutingStore extends RouterStore {
  constructor(public routes: AppRoutes, private errorReporter: ErrorReporter) {
    super();

    makeObservable<RoutingStore, 'matches' | 'isSubCategoryPage' | 'isRootPage' | 'isMicroCategoryPage'>(this, {
      matches: computed,
      appPage: computed,
      categorySlug: computed,
      subCategorySlug: computed,
      microCategorySlug: computed,
      alternateCanonicalLink: computed,
      canonicalLink: computed,
      page: computed,
      isCategoryPage: computed,
      isSubCategoryPage: computed,
      isMicroCategoryPage: computed,
      isRootPage: computed,
      isSearchResultsPage: computed,
      screen: computed,
      templateScreenURL: computed,
      searchResultsPageNumber: computed,
      searchQuery: computed,
      templateSlug: computed,
      search: action,
      goToScreen: action,
    });
  }

  private get matches(): Partial<Record<keyof AppRoutes, ReturnType<typeof matchPath>>> {
    return Object.keys(this.routes).reduce((res, key) => {
      let path: string;
      let route: PublicRoute;

      try {
        route = this.routes[key];
        path = typeof route === 'object' && route.pattern && route.pattern();
      } catch (error) {
        this.errorReporter.reportError(error, { message: `Failed trying to find ${key} in ${this.routes}` });
      }

      return {
        ...res,
        ...(path && {
          [key]: matchPath(this.location.pathname.toLowerCase(), { path }),
        }),
      };
    }, {});
  }

  get appPage(): AppPage | undefined {
    if (this.isRootPage) {
      return 'home';
    }
    if (this.isCategoryPage) {
      return 'category';
    }
    if (this.isSubCategoryPage) {
      return 'subCategory';
    }
    if (this.isMicroCategoryPage) {
      return 'microCategory';
    }
    if (this.isSearchResultsPage) {
      return 'searchResults';
    }

    return undefined;
  }

  get categorySlug(): string {
    const match = this.matches.categoryRoute;
    return match?.params?.categorySlug;
  }

  get microCategorySlug(): string {
    const match = this.matches.microCategoryRoute;
    return match?.params?.microCategorySlug;
  }

  get subCategorySlug(): string {
    const match = this.matches.subCategoryRoute;
    if (!this.isSubCategoryPage) {
      return undefined;
    }
    return match?.params?.subCategorySlug;
  }

  getRedirectTarget(totalPages?: number): string | null {
    if (!this.isValidPage(totalPages)) {
      if (this.isCategoryPage) {
        return this.routes.categoryRoute.get(this.categorySlug);
      }
      if (this.isSubCategoryPage) {
        return this.routes.subCategoryRoute.get(this.categorySlug, this.subCategorySlug);
      }
    }

    if (this.isCategoryPage && this.routes.isPrimaryCategoryRoute(this.categorySlug, this.page)) {
      return this.routes.base.get();
    }

    return null;
  }

  private getCanonicalLink(page?: number): string | undefined {
    if (this.appPage === 'microCategory') {
      return this.routes.microCategoryRoute.get(this.microCategorySlug, page);
    }
    if (this.appPage === 'category') {
      return this.routes.categoryRoute.get(this.categorySlug, page);
    }
    if (this.appPage === 'subCategory') {
      return this.routes.subCategoryRoute.get(this.categorySlug, this.subCategorySlug, page);
    }
    if (this.appPage === 'home') {
      return this.routes.base.get();
    }

    return undefined;
  }

  get alternateCanonicalLink(): string {
    return this.getCanonicalLink();
  }

  get canonicalLink(): string {
    return this.getCanonicalLink(this.page);
  }

  get page(): number | undefined {
    if (this.isSearchResultsPage) {
      return this.searchResultsPageNumber;
    }
    const categoryMatch = this.isCategoryPage && this.matches.categoryRoute;
    const subCategoryMatch = this.isSubCategoryPage && this.matches.subCategoryRoute;
    const microCategoryMatch = this.isMicroCategoryPage && this.matches.microCategoryRoute;

    const match = microCategoryMatch || categoryMatch || subCategoryMatch;
    return match?.params?.page && parseInt(match.params.page, 10);
  }

  private isValidPage(totalPages?: number): boolean {
    if (this.page === undefined) {
      return true;
    }

    return this.page > 1 && (this.page <= totalPages || totalPages === undefined);
  }

  public get isCategoryPage(): boolean {
    const match = this.matches.categoryRoute;
    return match && match.isExact;
  }

  private get isSubCategoryPage(): boolean {
    const match = this.matches.subCategoryRoute;
    return match && match.isExact && !this.isCategoryPage;
  }

  private get isMicroCategoryPage(): boolean {
    const match = this.matches.microCategoryRoute;
    return match && match.isExact;
  }

  private get isRootPage(): boolean {
    return !this.isCategoryPage && !this.isSearchResultsPage && !this.isSubCategoryPage && !this.isMicroCategoryPage;
  }

  public get isSearchResultsPage(): boolean {
    const match = this.matches.base;
    const parsed = urlParse(this.location.search, true);
    return match && !!parsed.query.criteria;
  }

  get searchResultsPageNumber(): number {
    const match = this.matches.base;
    const parsed = urlParse(this.location.search, true);
    return (match && parsed && parsed.query && parsed.query.page && parseInt(parsed.query.page, 10)) || 1;
  }

  get searchQuery(): string {
    const match = this.matches.base;
    const parsed = urlParse(this.location.search, true);
    return match && parsed.query.criteria;
  }

  get templateSlug(): string | undefined {
    if (this.screen) {
      const parsed = urlParse(this.location.search, true);
      return parsed.query['template-slug'];
    }

    return undefined;
  }

  search(query: string, page?: number) {
    const url = query ? this.routes.searchRoute.get(query, page) : this.routes.categoryRoute.get('most-popular');
    this.history.push(url);
  }

  public get screen(): Screen {
    const { query } = urlParse(this.location.search, true);
    return ['details', 'preview', 'thank-you'].includes(query.screen) ? (query.screen as Screen) : 'templates';
  }

  public get templateScreenURL(): string | undefined {
    if (this.isRootPage) {
      return this.routes.base.get();
    }
    if (this.isCategoryPage) {
      return this.routes.categoryRoute.get(this.categorySlug, this.page);
    }
    if (this.isMicroCategoryPage) {
      return this.routes.microCategoryRoute.get(this.microCategorySlug, this.page);
    }
    if (this.isSubCategoryPage) {
      return this.routes.subCategoryRoute.get(this.categorySlug, this.subCategorySlug, this.page);
    }
    if (this.isSearchResultsPage) {
      return this.routes.searchRoute.get(this.searchQuery, this.searchResultsPageNumber);
    }

    return undefined;
  }

  public getToScreenURL(screen: Screen, templateSlug: string): string | undefined {
    const {
      baseScreenRoute,
      searchScreenRoute,
      subCategoryScreenRoute,
      categoryScreenRoute,
      microCategoryScreenRoute,
    } = this.routes;

    if (this.isRootPage) {
      return baseScreenRoute.get(screen, templateSlug);
    }
    if (this.isCategoryPage) {
      return categoryScreenRoute.get(this.categorySlug, screen, templateSlug, this.page);
    }
    if (this.isMicroCategoryPage) {
      return microCategoryScreenRoute.get(this.microCategorySlug, screen, templateSlug, this.page);
    }
    if (this.isSubCategoryPage) {
      return subCategoryScreenRoute.get(this.categorySlug, this.subCategorySlug, screen, templateSlug, this.page);
    }
    if (this.isSearchResultsPage) {
      return searchScreenRoute.get(this.searchQuery, screen, templateSlug, this.searchResultsPageNumber);
    }

    return undefined;
  }

  public goToScreen(screen: Screen, templateSlug: string) {
    const url = this.getToScreenURL(screen, templateSlug);
    url && this.history.push(url);
  }

  public goToTemplatesScreen() {
    const url = this.templateScreenURL;
    url && this.history.push(url);
  }
}
