import React from 'react';
import cs from 'classnames';
import { RoutingStore } from '../../stores/RoutingStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { TemplatePreviewStore } from '../../stores/TemplatePreviewStore';
import { SeoLink } from '../SeoLink/SeoLink';
import { InteractionsStore } from '../../stores/InteractionsStore';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { biLogger } from '../../utils/BILogger';
import s from './Filters.scss';

export interface FiltersProps {
  location?: {};
  dataHook?: string;
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
  categoriesStore: CategoriesStore;
  interactionsStore: InteractionsStore;
  labeledBy?: string;
  className?: string;
  templatePreviewStore: TemplatePreviewStore;
  seqClassName?: string;
  seqStart?: number;
  seqClasses?: { [key: string]: string };
  itemClassName?: string;
  setActiveRef?: (element: HTMLElement) => void;
}

interface FilterItemProps {
  className?: string;
  dest: string;
  label: string;
  isActive: boolean;
  onLinkClick: () => void;
  setActiveRef?: (element: HTMLElement) => void;
}

const FilterItem = ({ className = '', dest, label, isActive, onLinkClick, setActiveRef }: FilterItemProps) => {
  const itemClassNames = cs(s.filter, { [className]: className });
  const linkClassNames = cs(s.link, { [s.active]: isActive });

  const ref = isActive ? setActiveRef : undefined;

  const logBI = () => {
    const getCategorySlugFromLink = (link: string) => {
      const slugs = link.split('/');
      return slugs[slugs.length - 1];
    };

    const clickedCategory = getCategorySlugFromLink(dest);

    biLogger.logCategorySelect({ category: clickedCategory });
  };

  const onClick = () => {
    onLinkClick();
    logBI();
  };

  return (
    <li
      ref={ref}
      className={itemClassNames}
      data-hook="filter-wrap"
      data-bi-element="category"
      data-bi-element-value={label}
    >
      <SeoLink onClick={onClick} className={linkClassNames} data-hook="filter" to={dest}>
        {label}
      </SeoLink>
    </li>
  );
};

class FiltersCmp extends React.Component<FiltersProps> {
  public render() {
    const props = this.props;
    const routes = props.routingStore.routes;
    const cls = cs({
      [s.list]: true,
      [props.className]: props.className,
    });

    return (
      <ul className={cls} aria-labelledby={props.labeledBy}>
        {props.categoriesStore.filters.map((filter, i) => {
          const link = routes.categoryRoute.get(filter.id as any);
          const liCls = cs({
            [props.seqClasses && props.seqClasses[`${props.seqClassName}${props.seqStart + i}`]]:
              props.seqClasses && props.seqClassName,
            [props.itemClassName]: props.itemClassName,
          });
          return (
            <FilterItem
              className={liCls}
              setActiveRef={props.setActiveRef}
              onLinkClick={this.handleLinkClick}
              key={filter.id}
              dest={link}
              isActive={filter === props.categoriesStore.activeCategory}
              label={filter.title}
            />
          );
        })}
      </ul>
    );
  }

  private handleLinkClick = (): void => {
    this.props.interactionsStore.initInteraction('category_switch');
  };
}

export const Filters = injectStoresV1(
  'routingStore',
  'templatesStore',
  'categoriesStore',
  'templatePreviewStore',
  'interactionsStore',
)(FiltersCmp);
