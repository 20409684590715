import React, { useState } from 'react';
import { withTranslations, WithTranslations } from '../../../../utils/withTranslations';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { injectStoresV1 } from '../../../stores/injectStoresV1';
import { biLogger } from '../../../utils/BILogger';
import { CategoriesStore } from '../../../stores/CategoriesStore';
import { ConfigStore } from '../../../stores/ConfigStore';
import { RoutingStore } from '../../../stores/RoutingStore';
import { useEditorSessionId } from '../../../../v2/contexts/EditorSessionIdContext';
import { useSavedTemplate } from '../../../contexts/SavedTemplateContext';
import s from './SaveTemplateButton.scss';

interface SaveTemplateButtonProps extends WithTranslations {
  dataHook?: string;
  templatesStore: TemplatesStore;
  categoriesStore: CategoriesStore;
  configStore: ConfigStore;
  routingStore: RoutingStore;
}

const SaveTemplateButtonCmp: React.FC<SaveTemplateButtonProps> = ({
  t,
  dataHook,
  templatesStore,
  categoriesStore,
  configStore,
  routingStore,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { imagesMediaPlatformDomain } = configStore.config;
  const { editorSessionId, refreshEditorSessionId } = useEditorSessionId();
  const { setSavedTemplateEditorSessionId } = useSavedTemplate();

  if (!templatesStore.expandedTemplate) {
    return null;
  }

  const handleSaveButtonClick = async () => {
    setIsDisabled(true);
    const imageUrl = `https://${imagesMediaPlatformDomain}${templatesStore.expandedTemplate?.previewImg}`;

    biLogger.logSaveTemplateClick({
      category: categoriesStore.activeCategory?.id ?? '',
      subcategory: categoriesStore.activeSubCategory?.id ?? '',
      page_number: templatesStore.page,
      template_id: templatesStore.expandedTemplate?.siteId,
      metasiteid: templatesStore.expandedTemplate?.metaSiteId,
      esi: editorSessionId,
      imageUrl,
    });

    await setSavedTemplateEditorSessionId(templatesStore.expandedTemplate?.id, editorSessionId);

    refreshEditorSessionId();

    routingStore.goToScreen('thank-you', templatesStore.expandedTemplate?.id);
  };

  return (
    <button className={s.button} data-hook={dataHook} onClick={handleSaveButtonClick} disabled={isDisabled}>
      {t('template.newMobileFlow.preview.saveTemplate')}
    </button>
  );
};

export const SaveTemplateButton = withTranslations(
  injectStoresV1('templatesStore', 'categoriesStore', 'configStore', 'routingStore')(SaveTemplateButtonCmp),
);
