import React from 'react';
import { injectStoresV1 } from '../../stores/injectStoresV1';
import { ConfigStore } from '../../stores/ConfigStore';
import { DEFAULT_IMAGE_TYPES } from '../../../utils/imageTypes';
import { generateImgUrl, generateSrcSet, SrcSetProps } from './imageUtils';

interface TemplateThumbnailProps {
  dataHook: string;
  alt: string;
  isMobileImage?: boolean;
  ariaLabel: string;
  configStore: ConfigStore;
  imagePath: string;
  lazyLoad?: boolean;
  className?: string;
}

const TemplateThumbnailCmp: React.FC<TemplateThumbnailProps> = ({
  alt,
  dataHook,
  isMobileImage,
  ariaLabel,
  configStore,
  imagePath,
  lazyLoad = true,
  className,
}) => {
  const { imagesMediaPlatformDomain } = configStore.config;
  const imageUrl = generateImgUrl(imagesMediaPlatformDomain, imagePath);

  const srcSet = [].concat(
    ...DEFAULT_IMAGE_TYPES.map((type) => generateSrcSet({ imageUrl, type, isMobileImage })),
  ) as SrcSetProps[];
  const loading = lazyLoad ? 'lazy' : 'eager';
  return (
    <picture data-hook={dataHook}>
      {srcSet.map((scrSetProps) => (
        <source data-hook={`${dataHook}-source`} {...scrSetProps} />
      ))}
      <img
        src={imageUrl}
        alt={alt}
        data-hook={`${dataHook}-image`}
        loading={loading}
        className={className}
        aria-label={ariaLabel}
      />
    </picture>
  );
};

export const TemplateThumbnail = injectStoresV1('configStore')(TemplateThumbnailCmp);
