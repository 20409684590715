import React from 'react';

export const SidebarStateContext = React.createContext({
  isSidebarOpen: false,
  openSidebar: () => {},
  closeSidebar: () => {},
});

export const useSidebarStateContext = () => React.useContext(SidebarStateContext);

export const SidebarStateProvider: React.FC = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);

  return (
    <SidebarStateContext.Provider
      value={{
        isSidebarOpen,
        openSidebar,
        closeSidebar,
      }}
    >
      {children}
    </SidebarStateContext.Provider>
  );
};
