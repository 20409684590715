import { dataHooks } from '../../../libs/dataHooks';

export const thankYouDataHooks = dataHooks<{
  closeButton: {};
  desktopPicture: {};
  mobilePicture: {};
  title: {};
  ctaButton: {};
  errorCtaButton: {};
  loader: {};
  wixOwnerAppBanner: {};
  wixOwnerInstallButton: {};
}>('thankYou');
