import { ConsentPolicy } from '@wix/wix-bootstrap-api';
import { GeoData } from '@wix/wix-common-aspects';
import { Identity } from '../../utils/fetchIdentity';

export interface ConfigStoreState {
  imagesMediaPlatformDomain: string;
  consentPolicy?: ConsentPolicy;
  supportedLanguages: string[];
  domain: string;
  geoData: GeoData;
  currentLanguage: string;
  santaEditorBaseUrl: string;
  introReferrer: string;
  identity: Identity;
}

export class ConfigStore {
  constructor(public readonly config: ConfigStoreState) {}

  serialize(): ConfigStoreState {
    return this.config;
  }
}
