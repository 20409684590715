import { action, computed, observable, makeObservable } from 'mobx';
import { ApiRoutes } from '../routes/ApiRoutes';
import { TemplatesStore } from './TemplatesStore';
import { RoutingStore } from './RoutingStore';

export class TemplatePreviewStore {
  public isIframeLoaded: boolean = false;

  public get isPreviewOrDetailsScreen(): boolean {
    return !!(['preview', 'details'].includes(this.routingStore.screen) && this.templatesStore.expandedTemplate);
  }

  constructor(
    private routingStore: RoutingStore,
    private templatesStore: TemplatesStore,
    private apiRoutes: ApiRoutes,
  ) {
    makeObservable<TemplatePreviewStore>(this, {
      isIframeLoaded: observable,
      isPreviewOrDetailsScreen: computed,
      previewIframeUrl: computed,
      setIframeLoaded: action,
    });
  }

  get previewIframeUrl() {
    return this.isPreviewOrDetailsScreen
      ? this.apiRoutes.preview(this.templatesStore.expandedTemplate.metaSiteId)
      : null;
  }

  setIframeLoaded(isLoaded: boolean) {
    this.isIframeLoaded = isLoaded;
  }
}
