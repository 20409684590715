import React from 'react';
import { inject, observer } from 'mobx-react';
import { Stores } from './initStores';

export function injectStoresV1<StoreNames extends keyof Stores>(
  ...storeNames: StoreNames[]
): <Cmp extends React.ComponentType<{ [key in StoreNames]: Stores[key] }>>(
  cmp: Cmp,
) => React.ComponentType<Omit<React.ComponentProps<Cmp>, StoreNames>> {
  const injector = inject(...storeNames);

  // FIXME: need to upgrade mobx-react types
  // @ts-expect-error
  return (Cmp) => {
    const ob = observer(Cmp);
    return injector(ob);
  };
}
